import {
  Button,
  Label,
  TextInput,
  ToggleSwitch,
} from 'flowbite-react';
import { useState } from 'react';

import Spinner from '@/components/Spinner';
import useForm from '@/hooks/useForm';

type ProductIngredientFormProps = {
  callback: (
    values: Omit<Api.ProductIngredient, 'id'>,
  ) => void;
  initialState: Partial<Api.ProductIngredient>;
  cancelCallback: () => void;
  isLoading: boolean;
};

function ProductIngredientForm({
  callback,
  initialState,
  cancelCallback,
  isLoading,
}: ProductIngredientFormProps) {
  const { name, order, handleInputChange } =
    useForm(initialState);
  const [
    isProductIngredientActive,
    setIsProductIngredientActive,
  ] = useState(initialState.status === 'ACTIVE');

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    if (!name || !order) return;
    const status = (
      isProductIngredientActive ? 'ACTIVE' : 'INACTIVE'
    ) as Api.Status;
    callback({
      name,
      order,
      status,
    } as Api.ProductIngredient);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-6">
        <div>
          <Label htmlFor="pi-name">Nombre</Label>
          <TextInput
            id="pi-name"
            name="name"
            placeholder="Chocolate"
            className="mt-1"
            value={name}
            onChange={(e) => {
              handleInputChange(e.target);
            }}
          />
        </div>
        <div>
          <Label htmlFor="pi-order">Orden</Label>
          <TextInput
            id="pi-order"
            name="order"
            placeholder="Orden en que es mostrado en la app"
            className="mt-1"
            value={order}
            onChange={(e) => {
              handleInputChange(e.target);
            }}
            type="number"
            min={0}
          />
        </div>
        <ToggleSwitch
          className="hidden"
          checked={isProductIngredientActive}
          label="Publicado"
          onChange={setIsProductIngredientActive}
        />
        <div className="mb-6 flex justify-center gap-4">
          <Button
            color="main"
            type="submit"
            disabled={isLoading}>
            Guardar
            {isLoading && (
              <Spinner size="sm" className="ml-2" />
            )}
          </Button>
          <Button color="light" onClick={cancelCallback}>
            Cancelar
          </Button>
        </div>
      </div>
    </form>
  );
}

export default ProductIngredientForm;
