/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-refresh/only-export-components */
import request from 'axios';
import { Button, Toast } from 'flowbite-react';
import toast from 'react-hot-toast';
import { BsCheckLg } from 'react-icons/bs';
import { HiX } from 'react-icons/hi';

type Props = {
  children: React.ReactNode;
};

function MyToast({ children }: Props) {
  return (
    <div className="space-x-4 divide-x divide-gray-200 ">
      <Toast>
        <div className="text-sm font-normal mr-4">
          {children}
        </div>
        <Toast.Toggle />
      </Toast>
    </div>
  );
}

export function toastMsg(
  msg: Msg,
  Icon: React.ReactNode = (
    <BsCheckLg className="text-xl text-green-500" />
  ),
  duration = 3000,
) {
  toast.custom(
    () => (
      <MyToast>
        <div className="flex justify-center items-center">
          {Icon && <div className="mr-4">{Icon}</div>}
          <p className="text-sm font-medium text-gray-900 ">
            {msg.title}
          </p>
          {msg.description && (
            <p className="mt-1 text-sm text-gray-500">
              {msg.description}
            </p>
          )}
        </div>
      </MyToast>
    ),
    { duration },
  );
}

export function toastErr(msg: Msg) {
  toast.custom(() => (
    <MyToast>
      <div className="flex gap-2 items-center">
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500">
          <HiX className="h-5 w-5" />
        </div>
        <div className="ml-3 text-sm font-normal">
          <p className="text-sm font-medium text-red-900 ">
            {msg.title}
          </p>
          {msg.description && (
            <p className="mt-1 text-sm text-gray-500">
              {msg.description}
            </p>
          )}
        </div>
      </div>
    </MyToast>
  ));
}

export function toastErrRequest(err: unknown) {
  if (request.isAxiosError(err)) {
    if (err.response?.data?.error?.code) {
      toastErr({ title: err.response.data?.error?.code });
    } else if (err.response?.data?.error) {
      toastErr({ title: err.response.data?.error });
    } else {
      toastErr({ title: 'Algo salió mal' });
    }
    return;
  }
  toastErr({ title: err as string });
}

type ToastInteractiveProps = {
  msg: Msg;
  Icon?: React.ReactNode;
  onConfirm: { title: string; action: () => void };
  onCancel?: { title: string; action: () => void };
  duration?: number;
  toggleButton?: boolean;
};

export function toastInteractive({
  msg,
  Icon,
  onConfirm,
  onCancel,
  duration = 3000,
  toggleButton = false,
}: ToastInteractiveProps) {
  toast.custom(
    () => (
      <Toast>
        <div className="flex !items-start">
          {Icon && <div className="mr-4">{Icon}</div>}
          <div className="ml-3 text-sm font-normal">
            <span className="mb-1 text-sm font-semibold text-gray-900 ">
              {msg.title}
            </span>
            {msg.description && (
              <div className="mb-2 text-sm font-normal text-gray-900 ">
                {msg.description}
              </div>
            )}
            <div className="flex gap-2">
              {onConfirm && (
                <div className="w-full">
                  <Button
                    size="xs"
                    onClick={onConfirm.action}>
                    {onConfirm.title}
                  </Button>
                </div>
              )}
              {onCancel && (
                <div className="w-full">
                  <Button
                    onClick={onCancel.action}
                    color="light"
                    size="xs">
                    {onCancel.title}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {toggleButton && <Toast.Toggle />}
        </div>
      </Toast>
    ),
    { duration, position: 'bottom-center' },
  );
}
