import cx from 'classnames';

import { HiCheck } from 'react-icons/hi';

import { useMedia } from '@/api/media';
import QueryResult from '@/components/QueryResult';

type MediaListProps = {
  isOpen: boolean;
  view: 'list' | 'upload';
  selected: Api.Media | null;
  setSelected: (media: Api.Media) => void;
};

function MediaList({
  isOpen,
  view,
  selected,
  setSelected,
}: MediaListProps) {
  const media = useMedia(isOpen && view === 'list');

  if (!isOpen || view !== 'list') return null;

  return (
    <QueryResult query={media}>
      <ul className="flex flex-wrap gap-4 max-h-[60vh] overflow-y-scroll">
        {media.data?.results.map((m) => (
          <li
            key={m.id}
            onClick={() => {
              setSelected(m);
            }}
            className={cx(
              Number(selected?.id) === Number(m.id)
                ? 'border-2 border-blue-500'
                : 'border border-gray-200',
              'w-32 h-32  bg-gray-50  hover:border-blue-200 cursor-pointer overflow-hidden relative',
            )}>
            {selected?.id === m.id && (
              <div className="top-0 right-0 bg-blue-500 w-4 h-4 absolute">
                <HiCheck className="text-white" />
              </div>
            )}
            <img
              src={m.image}
              alt=""
              className="w-32 h-32 object-contain"
            />
          </li>
        ))}
      </ul>
    </QueryResult>
  );
}

export default MediaList;
