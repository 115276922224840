import { Navigate, RouteObject } from 'react-router-dom';

import OnHoldPage from '@/pages/employees/OnHoldPage';
import LoginPage from '@/pages/public/LoginPage';

const publicRoutes: RouteObject[] = [
  {
    path: '/',
    children: [
      { path: 'login', element: <LoginPage /> },
      { path: 'on-hold', element: <OnHoldPage /> },
      { path: '', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/login" />,
  },
];

export default publicRoutes;
