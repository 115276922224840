import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { HiPencilAlt } from 'react-icons/hi';
import ProductRawMaterialForm from '../ProductRawMaterialForm';

import { mutateProductRawMaterialUpdate } from '@/api/rawMaterials';
import {
  toastErrRequest,
  toastMsg,
} from '@/components/Toast';
import useProductCtx from '@/hooks/useProductCtx';

type ProductRawMaterialModalUpdateProps = {
  productRawMaterial: Api.ProductRawMaterial;
};

function ProductRawMaterialModalUpdate({
  productRawMaterial,
}: ProductRawMaterialModalUpdateProps) {
  const [isOpen, setOpen] = useState(false);
  const mutate = mutateProductRawMaterialUpdate();

  const { setLastTimeEdited } = useProductCtx();

  const handleUpdateProductRawMaterial = (
    productRawMaterialUpdated: Omit<
      Api.ProductRawMaterial,
      'id'
    >,
  ) => {
    mutate
      .mutateAsync({
        id: productRawMaterial.id,
        ...productRawMaterialUpdated,
      })
      .then(() => {
        setOpen(false);
        toastMsg({ title: 'Materia prima actualizada' });
        setLastTimeEdited(new Date());
      })
      .catch((reason: unknown) => {
        toastErrRequest(reason);
      });
  };

  return (
    <>
      <button
        type="button"
        className="flex items-center justify-center text-sm text-blue-700 hover:text-blue-500"
        onClick={() => {
          setOpen(!isOpen);
        }}>
        <HiPencilAlt className="mr-1 text-lg" />
        <span className="sr-only">Editar</span>
      </button>
      <Modal
        onClose={() => {
          setOpen(false);
        }}
        show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Editar tamaño</strong>
        </Modal.Header>
        <Modal.Body>
          <ProductRawMaterialForm
            callback={handleUpdateProductRawMaterial}
            initialState={{
              ...productRawMaterial,
            }}
            isLoading={mutate.isLoading}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductRawMaterialModalUpdate;
