import { useQuery } from 'react-query';
import { axios } from './axios';

import makeMutation from '@/hooks/makeMutation';

const PATH = '/admin/productRawMaterials';

const getProductRawMaterial = (
  productId: number,
): Promise<Api.Response<Api.ProductRawMaterial[]>> =>
  axios.get(`${PATH}/${productId}`);
const postProductRawMaterial = (
  productRawMaterial: Omit<Api.ProductRawMaterial, 'id'> & {
    ingredientId: number;
    productId: number;
  },
) => axios.post(PATH, productRawMaterial);
const putProductRawMaterial = (
  productRawMaterial: Partial<Api.ProductRawMaterial>,
) =>
  axios.put(
    `${PATH}/${productRawMaterial.id}`,
    productRawMaterial,
  );
const deleteProductRawMaterial = (id: number) =>
  axios.delete(`${PATH}/${id}`);

const useProductRawMaterial = (productId: number) =>
  useQuery(['productRawMaterials', productId], () =>
    getProductRawMaterial(productId),
  );
const mutateProductRawMaterial = makeMutation(
  ['/productRawMaterials'],
  postProductRawMaterial,
  [
    'productRawMaterials',
    'productIngredients',
    'productIngredientsAvailable',
  ],
);
const mutateProductRawMaterialUpdate = makeMutation(
  ['/productRawMaterials'],
  putProductRawMaterial,
  [
    'productRawMaterials',
    'productIngredients',
    'productIngredientsAvailable',
  ],
);
const mutateProductRawMaterialDelete = makeMutation(
  ['/productRawMaterials'],
  deleteProductRawMaterial,
  [
    'productRawMaterials',
    'productIngredients',
    'productIngredientsAvailable',
  ],
);

export {
  mutateProductRawMaterial,
  mutateProductRawMaterialDelete,
  mutateProductRawMaterialUpdate,
  useProductRawMaterial,
};
