import { useEffect, useReducer } from 'react';

import {
  StoreContext,
  StoreContextInterface,
} from './StoreContext';
import {
  initialState,
  storeReducer,
  types,
} from './StoreReducer';

type StoreProviderProps = {
  children: React.ReactNode;
  store: Api.Store;
};

function StoreProvider({
  children,
  store,
}: StoreProviderProps) {
  const [state, dispatch] = useReducer(
    storeReducer,
    initialState,
  );

  useEffect(() => {
    dispatch({ type: types.setStore, payload: store });
  }, [store]);

  const setStore = (payload: Api.Store) => {
    dispatch({ type: types.setStore, payload });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: StoreContextInterface = {
    ...state,
    store,
    storeId: store.id,
    setStore,
  };

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
}

export default StoreProvider;
