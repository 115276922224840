import cx from 'classnames';
import { Button, Label, Modal } from 'flowbite-react';
import { useState } from 'react';
import {
  HiOutlinePhotograph,
  HiOutlineUpload,
} from 'react-icons/hi';
import MediaList from './MediaList';
import MediaUpload from './MediaUpload';

type MediaModalProps = {
  title: string;
  onSuccess: (file: Api.Media) => void;
  initialState: Api.Media | null;
};

function MediaModal({
  title,
  onSuccess,
  initialState,
}: MediaModalProps) {
  const [isOpen, setOpen] = useState(false);
  const [view, setView] = useState<'list' | 'upload'>(
    'list',
  );
  const [selected, setSelected] =
    useState<Api.Media | null>(initialState);

  const handleClose = () => {
    setOpen(false);
    setSelected(null);
    setView('list');
  };

  const handleUploadedFile = (file: {
    fileId: number;
    fileUrl: string;
  }) => {
    setSelected({
      id: file.fileId,
      image: file.fileUrl,
    } as Api.Media);
    setView('list');
  };

  const handleSelectMedia = () => {
    if (!selected) return;
    onSuccess(selected);
    handleClose();
  };

  return (
    <>
      {initialState?.image && (
        <div className="flex p-2 flex-col w-full text-center">
          <Label htmlFor="image">Vista previa</Label>
          <img
            src={initialState.image}
            alt={initialState.name}
            className="w-20 h-20 object-contain mx-auto"
          />
        </div>
      )}
      <Button
        color="gray"
        className="flex items-center justify-center text-sm full"
        onClick={() => {
          setOpen(!isOpen);
        }}>
        <HiOutlinePhotograph className="mr-2 text-lg" />
        {title}
      </Button>
      <Modal onClose={handleClose} show={isOpen} size="8xl">
        <Modal.Header className="px-3 pt-3 pb-0">
          <span className="sr-only">
            Borrar lugar de entrega
          </span>
        </Modal.Header>
        <Modal.Body className="px-6 pb-6 pt-0 p-4">
          <div>
            <h2 className="text-2xl font-bold">
              Biblioteca de medios
            </h2>
            <p className="text-sm text-gray-500">
              Selecciona una imagen de la biblioteca de
              medios
            </p>
            <hr className="my-4" />
          </div>
          <div className="border-b border-gray-100 flex">
            <button
              type="button"
              className={cx(
                'px-4 py-2 text-sm hover:text-gray-700 hover:border-gray-300 flex items-center',
                view === 'list' &&
                  'text-blue-600 border-b-2 border-blue-600',
              )}
              onClick={() => {
                setView('list');
              }}>
              <HiOutlinePhotograph className="mr-2 text-lg" />
              Biblioteca de medios
            </button>
            <button
              type="button"
              className={cx(
                'px-4 py-2 text-sm hover:text-gray-700 hover:border-gray-300 flex items-center',
                view === 'upload' &&
                  'text-blue-600 border-b-2 border-blue-600',
              )}
              onClick={() => {
                setView('upload');
              }}>
              <HiOutlineUpload className="mr-2 text-lg" />
              Subir imagen
            </button>
          </div>
          <div className="p-4">
            <MediaUpload
              isOpen={isOpen}
              view={view}
              onSuccess={handleUploadedFile}
            />
            <MediaList
              isOpen={isOpen}
              view={view}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="p-3 flex justify-end">
          <Button color="gray" onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            color="primary"
            onClick={handleSelectMedia}
            disabled={!selected}>
            Seleccionar imágen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MediaModal;
