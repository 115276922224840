import { Button, Table } from 'flowbite-react';
import { useNavigate } from 'react-router';

import { HiPencilAlt } from 'react-icons/hi';

import DeleteStoreModal from './DeleteStoreModal';

function StoresTable({ stores }: { stores: Api.Store[] }) {
  const navigate = useNavigate();

  return (
    <Table className="min-w-full divide-y divide-gray-200 ">
      <Table.Head className="bg-gray-100 ">
        <Table.HeadCell>Nombre</Table.HeadCell>
        <Table.HeadCell>Dirección</Table.HeadCell>
        <Table.HeadCell>Administrador</Table.HeadCell>
        <Table.HeadCell />
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white  ">
        {stores.length === 0 && (
          <Table.Row className="hover:bg-gray-100 ">
            <Table.Cell
              colSpan={5}
              className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              No hay Tiendas
            </Table.Cell>
          </Table.Row>
        )}
        {stores.map((store) => (
          <Table.Row
            className="hover:bg-gray-100 "
            key={store.id}>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {store.name}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {store.address}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-sm font-normal text-gray-500 ">
              {store.admins[0] ? (
                <>
                  <div className="text-base font-semibold text-gray-900 ">
                    {`${store.admins[0].firstName} ${store.admins[0].lastName}`}
                  </div>
                  <div className="text-sm font-normal text-gray-500 ">
                    {store.admins[0].email}
                  </div>
                </>
              ) : (
                'Sin asignar'
              )}
            </Table.Cell>
            <Table.Cell className="space-x-2 whitespace-nowrap p-4">
              <div className="flex items-center gap-x-3 justify-end">
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    navigate(`/stores/edit/${store.id}`);
                  }}>
                  <HiPencilAlt className="mr-2 text-lg" />
                  Editar
                </Button>
                <DeleteStoreModal storeId={store.id} />
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default StoresTable;
