import { createContext } from 'react';

export type StoreContextInterface = {
  storeId: number;
  store: Api.Store | null;
  setStore: (store: Api.Store) => void;
};

const storeContextDefaults: StoreContextInterface = {
  storeId: 0,
  store: null,
  setStore: () => null,
};

export const StoreContext =
  createContext<StoreContextInterface>(
    storeContextDefaults,
  );
