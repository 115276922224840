import {
  Button,
  Label,
  TextInput,
  ToggleSwitch,
} from 'flowbite-react';
import { useState } from 'react';

import Spinner from '@/components/Spinner';
import useForm from '@/hooks/useForm';
import MediaModal from '../media/MediaModal';

type ProductRawMaterialFormProps = {
  callback: (
    values: Omit<Api.ProductRawMaterial, 'id'>,
  ) => void;
  initialState: Partial<Api.ProductRawMaterial>;
  isLoading: boolean;
};

function ProductRawMaterialForm({
  callback,
  initialState,
  isLoading,
}: ProductRawMaterialFormProps) {
  const [media, setMedia] = useState<Api.Media | null>(
    initialState.imageUrl
      ? ({
          id: 0,
          name: '',
          image: initialState.imageUrl,
        } as Api.Media)
      : null,
  );

  const { name, order, inventoryCost, handleInputChange } =
    useForm(initialState);
  const [
    isProductRawMaterialActive,
    setIsProductRawMaterialActive,
  ] = useState(initialState.status === 'ACTIVE');
  const [isEnableInventory, setIsEnableInventory] =
    useState(initialState.enableInventory ?? false);
  const [isUnique, setIsUnique] = useState(
    initialState.unique ?? false,
  );
  const [formErrors, setFormErrors] = useState<
    Record<string, string>
  >({});

  const handleValidation = () => {
    const errors: Record<string, string> = {};
    if (!name) errors.name = 'El nombre es obligatorio';
    if (!order) errors.order = 'El orden es obligatorio';
    if (!media?.image)
      errors.imageUrl = 'La imagen es obligatoria';
    if (isEnableInventory && !inventoryCost)
      errors.inventoryCost =
        'El costo de inventario es obligatorio';
    setFormErrors(errors);
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    if (
      !name ||
      !media?.image ||
      !order ||
      (isEnableInventory && !inventoryCost)
    ) {
      handleValidation();
      return;
    }
    const status = (
      isProductRawMaterialActive ? 'ACTIVE' : 'INACTIVE'
    ) as Api.Status;
    callback({
      name,
      imageUrl: media.image,
      order,
      status,
      ...(isEnableInventory
        ? { enableInventory: true, inventoryCost }
        : { enableInventory: false }),
      unique: isUnique,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-6">
        <div>
          <Label
            htmlFor="productMaterialRaw-name"
            color={formErrors.name ? 'failure' : ''}>
            Nombre
          </Label>
          <TextInput
            id="productMaterialRaw-name"
            name="name"
            placeholder="Oreo"
            className="mt-1"
            value={name}
            onChange={(e) => {
              handleInputChange(e.target);
            }}
            color={formErrors.name ? 'failure' : ''}
            helperText={
              formErrors.name ? (
                <small className="text-red-500">
                  {formErrors.name}
                </small>
              ) : (
                ''
              )
            }
            required
          />
        </div>

        <MediaModal
          onSuccess={setMedia}
          initialState={media}
          key={media?.id}
          title={
            media?.image
              ? 'Cambiar Imagen'
              : 'Establecer imagen'
          }
        />
        {formErrors.imageUrl && (
          <small className="text-red-500">
            {formErrors.imageUrl}
          </small>
        )}

        <div>
          <Label
            htmlFor="productMaterialRaw-order"
            color={formErrors.order ? 'failure' : ''}>
            Orden
          </Label>
          <TextInput
            id="productMaterialRaw-order"
            name="order"
            placeholder="Orden en que es mostrado en la app"
            className="mt-1"
            value={order}
            onChange={(e) => {
              handleInputChange(e.target);
            }}
            type="number"
            min={0}
            color={formErrors.order ? 'failure' : ''}
            helperText={
              formErrors.order ? (
                <small className="text-red-500">
                  {formErrors.order}
                </small>
              ) : (
                ''
              )
            }
            required
          />
        </div>
        <ToggleSwitch
          checked={isEnableInventory}
          label="Manejo de Inventario"
          onChange={setIsEnableInventory}
        />
        {isEnableInventory && (
          <div>
            <Label
              htmlFor="productMaterialRaw-inventoryCost"
              color={
                formErrors.inventoryCost ? 'failure' : ''
              }>
              Costo de inventario
            </Label>
            <TextInput
              id="productMaterialRaw-inventoryCost"
              name="inventoryCost"
              placeholder="Costo de inventario"
              className="mt-1"
              value={inventoryCost}
              onChange={(e) => {
                handleInputChange(e.target);
              }}
              type="number"
              min={0}
              color={
                formErrors.inventoryCost ? 'failure' : ''
              }
              helperText={
                formErrors.inventoryCost ? (
                  <small className="text-red-500">
                    {formErrors.inventoryCost}
                  </small>
                ) : (
                  ''
                )
              }
              required
            />
          </div>
        )}
        <ToggleSwitch
          className="hidden"
          checked={isUnique}
          label="Es único?"
          onChange={setIsUnique}
        />

        <ToggleSwitch
          className="hidden"
          checked={isProductRawMaterialActive}
          label="Publicado"
          onChange={setIsProductRawMaterialActive}
        />
        <hr className="my-4" />
        <div className="mb-6 flex items-center justify-between">
          <Button
            color="main"
            type="submit"
            className="w-full lg:w-auto"
            disabled={isLoading}>
            Guardar
            {isLoading && (
              <Spinner size="sm" className="ml-2" />
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default ProductRawMaterialForm;
