import { Timeline } from 'flowbite-react';
import { useEffect } from 'react';
import { HiOutlineDeviceMobile } from 'react-icons/hi';

import { useProductIngredients } from '@/api/ingredients';
import QueryResult from '@/components/QueryResult';
import useProductCtx from '@/hooks/useProductCtx';
import ProductIngredientList from './ProductIngredientList';
import ProductIngredientModalAdmin from './modals/ProductIngredientModalAdmin';

function ProductIngredients() {
  const { productId, setProductIngredients } =
    useProductCtx();
  const productIngredients =
    useProductIngredients(productId);

  useEffect(() => {
    if (
      productIngredients.data &&
      !productIngredients.isLoading &&
      !productIngredients.isError
    ) {
      setProductIngredients(
        productIngredients.data.results ?? [],
      );
    }
  }, [
    productIngredients.data,
    productIngredients.isLoading,
    productIngredients.isError,
  ]);

  return (
    <Timeline.Item>
      <Timeline.Point icon={HiOutlineDeviceMobile} />
      <Timeline.Content>
        <Timeline.Title className="flex items-center gap-5">
          Ingredientes
          <ProductIngredientModalAdmin />
        </Timeline.Title>
        <Timeline.Body>
          <div className="mt-4">
            <QueryResult query={productIngredients}>
              <ProductIngredientList
                productIngredients={
                  productIngredients.data?.results ?? []
                }
              />
            </QueryResult>
          </div>
        </Timeline.Body>
      </Timeline.Content>
    </Timeline.Item>
  );
}

export default ProductIngredients;
