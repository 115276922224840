import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';

import { HiPencilAlt } from 'react-icons/hi';

import {
  mutateProductIngredientUpdate,
  useProductIngredient,
} from '@/api/ingredients';
import QueryResult from '@/components/QueryResult';
import {
  toastErrRequest,
  toastMsg,
} from '@/components/Toast';
import ProductIngredientForm from '../ProductIngredientForm';

type ProductIngredientModalUpdateProps = {
  productIngredientId: number;
};

function ProductIngredientModalUpdate({
  productIngredientId,
}: ProductIngredientModalUpdateProps) {
  const [isOpen, setOpen] = useState(false);
  const productIngredient = useProductIngredient(
    productIngredientId,
    isOpen,
  );

  const mutate = mutateProductIngredientUpdate();

  const handleUpdateProductIngredient = (
    productIngredientUpdated: Omit<
      Api.ProductIngredient,
      'id'
    >,
  ) => {
    mutate
      .mutateAsync({
        id: productIngredientId,
        ...productIngredientUpdated,
      })
      .then(() => {
        setOpen(false);
        toastMsg({ title: 'Ingrediente actualizado' });
      })
      .catch((reason: unknown) => {
        toastErrRequest(reason);
      });
  };

  return (
    <>
      <Button
        size="xs"
        onClick={() => {
          setOpen(!isOpen);
        }}>
        <HiPencilAlt className="mr-1 text-lg" />
        Editar
      </Button>
      <Modal
        onClose={() => {
          setOpen(false);
        }}
        show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 ">
          <strong>Administrar Ingrediente</strong>
        </Modal.Header>
        <Modal.Body>
          <QueryResult query={productIngredient}>
            {productIngredient.data && (
              <ProductIngredientForm
                key={`${productIngredientId}-${productIngredient.data.updatedAt}`}
                callback={handleUpdateProductIngredient}
                initialState={productIngredient.data}
                cancelCallback={() => {
                  setOpen(false);
                }}
                isLoading={mutate.isLoading}
              />
            )}
          </QueryResult>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductIngredientModalUpdate;
