import type { CustomFlowbiteTheme } from 'flowbite-react';

const flowbiteTheme: CustomFlowbiteTheme = {
  badge: {
    color: {
      primary:
        'bg-primary-100 text-primary-800 group-hover:bg-primary-200',
    },
    icon: {
      off: 'rounded-full px-2 py-1',
    },
    size: {
      xl: 'px-3 py-2 text-base rounded-md',
    },
  },
  button: {
    color: {
      main: 'text-white bg-main hover:bg-main-darken focus:ring-secondary active:bg-main-darken ',
      primary:
        'text-white bg-primary-700 hover:bg-primary-800 focus:ring-primary-300',
    },
    outline: {
      on: 'transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit',
    },
    size: {
      md: 'text-sm px-3 py-2',
    },
  },
  dropdown: {
    floating: {
      base: 'z-10 w-fit rounded-xl divide-y divide-gray-100 shadow',
      content: 'rounded-xl text-sm text-gray-700',
      target: 'w-fit',
    },
    content: '',
  },
  modal: {
    content: {
      inner: 'relative rounded-lg bg-white shadow',
    },
    header: {
      base: 'flex items-start justify-between rounded-t px-5 pt-5',
    },
  },
  navbar: {
    base: 'fixed z-30 w-full bg-white border-b border-gray-200',
  },
  sidebar: {
    base: 'flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width',
  },
  textarea: {
    base: 'block w-full text-sm p-4 rounded-lg border disabled:cursor-not-allowed disabled:opacity-50',
  },
  toggleSwitch: {
    toggle: {
      checked: {
        off: '!border-gray-200 !bg-gray-200',
      },
    },
  },
};

export default flowbiteTheme;
