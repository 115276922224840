import { Timeline } from 'flowbite-react';
import ProductIngredients from '../productIngredient/ProductIngredients';
import ProductSizes from '../productSize/ProductSizes';

function ProductVariations() {
  return (
    <div className="col-span-2 mb-4 rounded-lg bg-white p-4 shadow  sm:p-6 xl:p-8">
      <h1 className="text-xl font-semibold text-gray-900  sm:text-2xl">
        Detalles del Producto
      </h1>
      <div className="my-3 mx-4">
        <Timeline>
          <ProductSizes />
          <ProductIngredients />
        </Timeline>
      </div>
    </div>
  );
}

export default ProductVariations;
