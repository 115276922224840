import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import ProductSizeForm from '../ProductSizeForm';

import { mutateProductSize } from '@/api/sizes';
import {
  toastErrRequest,
  toastMsg,
} from '@/components/Toast';
import useProductCtx from '@/hooks/useProductCtx';

function ProductSizeModalCreate() {
  const { productId } = useProductCtx();
  const [isOpen, setOpen] = useState(false);
  const [initialState, setInitialState] = useState<
    Partial<Api.ProductSize>
  >({});
  const [formKey, setFormKey] = useState(0);

  const mutate = mutateProductSize();

  const handleCloseModal = () => {
    setOpen(false);
    setInitialState({});
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleUpdateProductSize = (
    productSizeUpdated: Omit<Api.ProductSize, 'id'>,
  ) => {
    mutate
      .mutateAsync({
        id: 90,
        ...productSizeUpdated,
        productId,
      })
      .then(() => {
        toastMsg({ title: 'Tamaño creado' });
        handleCloseModal();
      })
      .catch((reason: unknown) => {
        toastErrRequest(reason);
      });
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        size="xs">
        Agregar tamaño
      </Button>
      <Modal onClose={handleCloseModal} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 ">
          <strong>Agregar tamaño</strong>
        </Modal.Header>
        <Modal.Body>
          <ProductSizeForm
            key={formKey}
            callback={handleUpdateProductSize}
            initialState={initialState}
            isLoading={mutate.isLoading}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductSizeModalCreate;
