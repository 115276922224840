import { Modal } from 'flowbite-react';

import { TbEdit } from 'react-icons/tb';

function AdminModalUpdate() {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm bg-green-500 text-white rounded-full p-1 hover:bg-green-400">
        <TbEdit className="text-base" />
      </button>
      <Modal>
        <Modal.Header>
          <strong>Update</strong>
        </Modal.Header>
        <Modal.Body>AdminForm</Modal.Body>
      </Modal>
    </>
  );
}

export default AdminModalUpdate;
