import { Modal } from 'flowbite-react';
import { HiOutlinePlus } from 'react-icons/hi';

function AdminModalCreate() {
  return (
    <>
      <button
        className="flex items-center text-sm text-green-500 border-green-500 hover:bg-green-700 border p-1 rounded-md hover:text-white transition duration-300 ease-in-out"
        type="button">
        <HiOutlinePlus className="text-2xl" />
      </button>
      <Modal>
        <Modal.Header>
          <strong>Agregar administrador</strong>
        </Modal.Header>
        <Modal.Body>AdminForm</Modal.Body>
      </Modal>
    </>
  );
}

export default AdminModalCreate;
