import ProductSizeListItem from './ProductSizeListItem';

type ProductSizeListProps = {
  productSizes: Api.ProductSize[];
};

function ProductSizeList({
  productSizes,
}: ProductSizeListProps) {
  if (productSizes.length === 0) {
    return <small className="my-4">No hay tamaños</small>;
  }
  return (
    <ul className="my-4 flex flex-wrap gap-4 justify-center">
      {productSizes.map((productSize) => (
        <ProductSizeListItem
          key={productSize.id}
          productSize={productSize}
        />
      ))}
    </ul>
  );
}

export default ProductSizeList;
