import { Breadcrumb, Button } from 'flowbite-react';
import { Link } from 'react-router-dom';

import { FaPlus } from 'react-icons/fa';
import { HiHome } from 'react-icons/hi';

import { useProducts } from '@/api/products';
import QueryResult from '@/components/QueryResult';
import ProductsTable from '@/features/products/ProductsTable';
import NavbarSidebar from '@/layouts/NavbarSidebar';

function ProductsPage() {
  const products = useProducts();
  return (
    <NavbarSidebar>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4   sm:flex">
        <div className="w-full">
          <div className="flex justify-between items-center">
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="">Inicio</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Productos</Breadcrumb.Item>
            </Breadcrumb>
            <Link to="/products/create">
              <Button color="primary" className="text-sm">
                <FaPlus className="mr-3 text-sm" />
                Agregar Producto
              </Button>
            </Link>
          </div>
          <div className="block items-center sm:flex">
            <div className="flex w-full items-center sm:justify-end" />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <QueryResult query={products}>
          <ProductsTable
            products={products?.data?.results ?? []}
          />
        </QueryResult>
      </div>
    </NavbarSidebar>
  );
}

export default ProductsPage;
