function ErrorFallback() {
  return (
    <div
      className="text-white w-screen h-screen flex flex-col justify-center items-center"
      role="alert">
      <img
        src="/logo.png"
        className="max-h-20"
        alt="app-logo"
      />
      <h2 className="text-lg font-semibold my-3">
        ¡Ups! Algo salió mal
      </h2>
      <button
        type="button"
        className="btn bg-main px-4 py-2 rounded-full"
        onClick={() => {
          window.location.reload();
        }}>
        Recargar
      </button>
    </div>
  );
}

export default ErrorFallback;
