import { Breadcrumb } from 'flowbite-react';
import { useNavigate } from 'react-router';

import { HiHome } from 'react-icons/hi';

import { mutateStore } from '@/api/stores';
import { toastMsg } from '@/components/Toast';
import StoreForm, {
  StoreFormValues,
} from '@/features/stores/StoreForm';
import NavbarSidebar from '@/layouts/NavbarSidebar';

function StorePageCreate() {
  const mutate = mutateStore();
  const navigate = useNavigate();

  const handleCreateStore = async ({
    name,
    address,
  }: StoreFormValues) => {
    const store = { name, address } as Api.Store;
    await mutate.mutateAsync(store).then(() => {
      navigate('/stores');
      toastMsg({ title: 'Tienda creada' });
    });
  };

  return (
    <NavbarSidebar>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4   sm:flex">
        <div className="w-full">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <div className="flex items-center gap-x-3">
                <HiHome className="text-xl" />
                <span className="">Inicio</span>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/stores">
              Tiendas
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Agregar Tienda
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto p-4">
          <div className="mb-4 rounded-lg bg-white p-4 shadow  sm:p-6 xl:p-8">
            <h1 className="text-xl font-semibold text-gray-900  sm:text-2xl">
              Tienda
            </h1>
            <StoreForm
              callback={handleCreateStore}
              initialState={{ name: '', address: '' }}
              isLoading={mutate.isLoading}
            />
          </div>
        </div>
      </div>
    </NavbarSidebar>
  );
}

export default StorePageCreate;
