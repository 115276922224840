import formatMoney from '@/helpers/money';

function ProductTablePrice({
  product,
}: {
  product: Api.Product;
}) {
  const { type, basePrice, productSizes } = product;
  if (type === 'SIMPLE') {
    return <>{formatMoney(basePrice)}</>;
  }

  const minPrice = productSizes
    .map((size) => size.basePrice)
    .sort()[0];
  const maxPrice = productSizes
    .map((size) => size.basePrice)
    .sort()
    .reverse()[0];

  if (minPrice === maxPrice) {
    return <>{formatMoney(minPrice)}</>;
  }

  return (
    <>
      {formatMoney(minPrice)} - {formatMoney(maxPrice)}
    </>
  );
}

export default ProductTablePrice;
