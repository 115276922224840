import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import {
  HiOutlineExclamationCircle,
  HiTrash,
} from 'react-icons/hi';

import { mutateProductSizeDelete } from '@/api/sizes';
import { toastMsg } from '@/components/Toast';

type ProductSizeModalDeleteProps = {
  productSizeId: number;
};

function ProductSizeModalDelete({
  productSizeId,
}: ProductSizeModalDeleteProps) {
  const [isOpen, setOpen] = useState(false);
  const mutate = mutateProductSizeDelete();

  const handleDeleteProductSize = async () => {
    await mutate.mutateAsync(productSizeId).then(() => {
      setOpen(false);
      toastMsg({ title: 'Tamaño eliminado' });
    });
  };

  const handleClose = () => {
    if (mutate.isLoading) return;
    setOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className="flex items-center justify-center text-sm text-red-700 mt-2 hover:text-white hover:bg-red-700 p-1 rounded-full"
        onClick={() => {
          setOpen(!isOpen);
        }}>
        <HiTrash className="text-lg" />
      </button>
      <Modal onClose={handleClose} show={isOpen} size="md">
        <Modal.Header className="px-3 pt-3 pb-0">
          <span className="sr-only">Borrar tamaño</span>
        </Modal.Header>
        <Modal.Body className="px-6 pb-6 pt-0">
          <div className="flex flex-col items-center gap-y-6 text-center">
            <HiOutlineExclamationCircle className="text-7xl text-red-600" />
            <p className="text-lg text-gray-500 ">
              Estás seguro que quieres borrar este tamaño?
            </p>
            <div className="flex items-center gap-x-3">
              <Button
                color="failure"
                onClick={handleDeleteProductSize}>
                Sí, borrar
              </Button>
              <Button color="gray" onClick={handleClose}>
                No, cancelar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductSizeModalDelete;
