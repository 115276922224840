import classNames from 'classnames';
import { Footer } from 'flowbite-react';
import type { PropsWithChildren } from 'react';

import Navbar from '@/components/Navbar';
import Sidebar from '@/components/Sidebar';
import { version } from '@/version';
import {
  SidebarProvider,
  useSidebarContext,
} from '../context/SidebarContext';

function MainContentFooter() {
  return (
    <>
      <Footer container>
        <div className="grid place-items-center grow text-sm text-gray-600">
          Versión: {version}
        </div>
      </Footer>
      <p className="my-8 text-center text-sm text-gray-500 ">
        &copy;
        {` ${new Date().getFullYear()}  Chaska. Todos los derechos reservados.`}
      </p>
    </>
  );
}

function MainContent({
  children,
  isFooter,
}: PropsWithChildren<NavbarSidebarLayoutProps>) {
  const { isOpenOnSmallScreens: isSidebarOpen } =
    useSidebarContext();

  return (
    <main
      className={classNames(
        'overflow-y-auto relative w-full h-full bg-gray-50 ',
        isSidebarOpen ? 'lg:ml-16' : 'lg:ml-64',
      )}>
      {children}
      {isFooter && (
        <div className="mx-4 mt-4">
          <MainContentFooter />
        </div>
      )}
    </main>
  );
}

type NavbarSidebarLayoutProps = {
  isFooter?: boolean;
};

function NavbarSidebarLayout({
  children,
  isFooter = true,
}: PropsWithChildren<NavbarSidebarLayoutProps>) {
  return (
    <SidebarProvider>
      <Navbar />
      <div className="flex items-start pt-16">
        <Sidebar />
        <MainContent isFooter={isFooter}>
          {children}
        </MainContent>
      </div>
    </SidebarProvider>
  );
}

export default NavbarSidebarLayout;
