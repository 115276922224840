import { Modal } from 'flowbite-react';
import { LiaUserAltSlashSolid } from 'react-icons/lia';

function AdminModalDisable() {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm bg-red-500 text-white rounded-full p-1 hover:bg-red-400">
        <LiaUserAltSlashSolid className="text-base" />
      </button>
      <Modal>
        <Modal.Header>
          <strong>Update</strong>
        </Modal.Header>
        <Modal.Body>AdminForm</Modal.Body>
      </Modal>
    </>
  );
}

export default AdminModalDisable;
