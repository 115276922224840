import { Avatar, Dropdown } from 'flowbite-react';

import useAuth from '@/hooks/useAuth';

function UserDropdown() {
  const { user, handleLogout } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={
        <span>
          <span className="sr-only">User menu</span>
          <Avatar
            alt=""
            img={`https://gravatar.com/avatar/${user.emailHash}`}
            rounded
            size="sm"
          />
        </span>
      }>
      <Dropdown.Header>
        <span className="block text-sm">{`${user.firstName} ${user.lastName}`}</span>
        <span className="block truncate text-sm font-medium">
          {user.email}
        </span>
      </Dropdown.Header>
      <Dropdown.Item>Dashboard</Dropdown.Item>
      <Dropdown.Item>Settings</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item onClick={handleLogout}>
        Sign out
      </Dropdown.Item>
    </Dropdown>
  );
}

export default UserDropdown;
