import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { HiOutlineUpload } from 'react-icons/hi';

import { mutateMedia } from '@/api/media';
import Loader from '@/components/Loader';
import { toastErr, toastMsg } from '@/components/Toast';

type MediaUploadProps = {
  isOpen: boolean;
  view: 'list' | 'upload';
  onSuccess: ({
    fileId,
    fileUrl,
  }: {
    fileId: number;
    fileUrl: string;
  }) => void;
};

function MediaUpload({
  isOpen,
  view,
  onSuccess,
}: MediaUploadProps) {
  const mutate = mutateMedia();
  const [filePreview, setFilePreview] = useState<
    string | null
  >(null);

  const handleUpload = (fileToUpload: File) => {
    if (!fileToUpload) return;

    setFilePreview(URL.createObjectURL(fileToUpload));
    mutate
      .mutateAsync(fileToUpload)
      .then((res) => {
        toastMsg({ title: 'Upload success' });
        onSuccess({
          fileId: res.results.id,
          fileUrl: res.results.image,
        });
      })
      .catch(() => {
        toastErr({ title: 'Upload failed' });
        setFilePreview(null);
      });
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    handleUpload(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      mimeType: ['image/*'],
      extensions: ['png', 'jpg', 'jpeg', 'gif'],
    },
  });

  if (!isOpen || view !== 'upload') return null;

  return (
    <form className="w-full">
      {mutate.isLoading && <Loader />}
      {mutate.isLoading && filePreview && (
        <div>
          <p>Previsualización:</p>
          <img
            src={filePreview}
            alt="preview"
            className="w-full h-96 object-contain"
          />
          <small>Subiendo archivo...</small>
        </div>
      )}
      {!mutate.isLoading && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <HiOutlineUpload className="text-3xl text-gray-500" />
          <p>
            <span className="font-semibold">
              Click para subir
            </span>{' '}
            o arrastre la imagen aquí
          </p>
        </div>
      )}
    </form>
  );
}

export default MediaUpload;
