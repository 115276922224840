import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import {
  HiOutlineExclamationCircle,
  HiTrash,
} from 'react-icons/hi';

import { mutateProductIngredientDelete } from '@/api/ingredients';
import {
  toastErrRequest,
  toastMsg,
} from '@/components/Toast';

type ProductIngredientModalDeleteProps = {
  productIngredientId: number;
  callback: () => void;
};

function ProductIngredientModalDelete({
  productIngredientId,
  callback,
}: ProductIngredientModalDeleteProps) {
  const [isOpen, setOpen] = useState(false);
  const mutate = mutateProductIngredientDelete();

  const handleDeleteProductIngredient = () => {
    mutate
      .mutateAsync(productIngredientId)
      .then(() => {
        setOpen(false);
        toastMsg({ title: 'Ingrediente eliminado' });
        callback();
      })
      .catch((error: unknown) => {
        toastErrRequest(error);
      });
  };

  const handleClose = () => {
    if (mutate.isLoading) return;
    setOpen(false);
  };

  return (
    <>
      <Button
        color="failure"
        onClick={() => {
          setOpen(!isOpen);
        }}
        size="xs">
        <HiTrash className="text-base mr-2" />
        Borrar ingrediente
      </Button>
      <Modal onClose={handleClose} show={isOpen} size="md">
        <Modal.Header className="px-3 pt-3 pb-0">
          <span className="sr-only">
            Borrar ingrediente
          </span>
        </Modal.Header>
        <Modal.Body className="px-6 pb-6 pt-0">
          <div className="flex flex-col items-center gap-y-6 text-center">
            <HiOutlineExclamationCircle className="text-7xl text-red-600" />
            <p className="text-lg text-gray-500 ">
              Estás seguro que quieres borrar este
              ingrediente?
            </p>
            <div className="flex items-center gap-x-3">
              <Button
                color="failure"
                onClick={handleDeleteProductIngredient}>
                Sí, borrar
              </Button>
              <Button color="gray" onClick={handleClose}>
                No, cancelar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductIngredientModalDelete;
