import { Navigate, RouteObject } from 'react-router-dom';

import HomePage from '@/pages/dashboard/HomePage';

import ProductPageUpdate from '@/pages/dashboard/product/ProductPageUpdate';
import ProductsPage from '@/pages/dashboard/product/ProductsPage';

import OrderDetailedPage from '@/pages/dashboard/orders/OrderDetailedPage';
import OrdersPage from '@/pages/dashboard/orders/OrdersPage';
import StorePageCreate from '@/pages/dashboard/store/StorePageCreate';
import StorePageUpdate from '@/pages/dashboard/store/StorePageUpdate';
import StoresPage from '@/pages/dashboard/store/StoresPage';

import ResumePage from '@/pages/dashboard/ResumePage';
import UsersPage from '@/pages/dashboard/users/UsersPage';
import OnHoldPage from '@/pages/employees/OnHoldPage';

const protectedRoutes: RouteObject[] = [
  {
    path: '/',
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/stores', element: <StoresPage /> },
      {
        path: '/stores/create',
        element: <StorePageCreate />,
      },
      {
        path: '/stores/edit/:id',
        element: <StorePageUpdate />,
      },
      { path: '/products', element: <ProductsPage /> },
      {
        path: '/products/edit/:id',
        element: <ProductPageUpdate />,
      },
      { path: '/orders', element: <OrdersPage /> },
      {
        path: '/orders/:id',
        element: <OrderDetailedPage />,
      },
      { path: '/on-hold', element: <OnHoldPage /> },
      { path: '/resume', element: <ResumePage /> },
      { path: '/users', element: <UsersPage /> },

      { path: '/login', element: <Navigate to="/" /> },
      { path: '*', element: <p>Not Found</p> },
    ],
  },
  {
    path: '*',
    element: <p>Not Found</p>,
  },
];

export default protectedRoutes;
