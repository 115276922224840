export const types = {
  setStore: 'store/setStore',
};

export const initialState = {
  storeId: 0,
  store: {} as Api.Store,
};

export const storeReducer = (
  state: typeof initialState,
  action: { type: string; payload: Api.Store },
) => {
  switch (action.type) {
    case types.setStore:
      return { ...state, store: action.payload };
    default:
      return state;
  }
};
