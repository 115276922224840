import { useQuery } from 'react-query';
import { axios } from './axios';

import makeMutation from '@/hooks/makeMutation';

type IngredientToProduct = {
  productId: number;
  productIngredientId: number;
};

const getProductIngredients = (
  productId: number,
): Promise<Api.Response<Api.ProductIngredient[]>> =>
  axios.get(
    `/admin/products/${productId}/productIngredient`,
  );
const getProductIngredient = (
  productIngredientId: number,
): Promise<Api.ProductIngredient> =>
  axios.get(
    `/admin/productIngredient/${productIngredientId}`,
  );
const getProductIngredientsAvailable = (
  productId: number,
): Promise<
  Api.Response<Api.ProductIngredientsAvailable[]>
> =>
  axios.get(
    `/admin/products/${productId}/productIngredient/available`,
  );
const postProductIngredient = (
  productIngredient: Omit<Api.ProductIngredient, 'id'> & {
    id: number;
    productId: number;
  },
) =>
  axios.post('/admin/productIngredient', productIngredient);
const postProductIngredientToProduct = (
  product: IngredientToProduct,
) =>
  axios.post(
    `/admin/products/${product.productId}/productIngredient/${product.productIngredientId}`,
  );
const putProductIngredient = (
  productIngredient: Partial<Api.ProductIngredient>,
) =>
  axios.put(
    `/admin/productIngredient/${productIngredient.id}`,
    productIngredient,
  );
const deleteProductIngredientFromProduct = (
  product: IngredientToProduct,
) =>
  axios.delete(
    `/admin/products/${product.productId}/productIngredient/${product.productIngredientId}`,
  );
const deleteProductIngredient = (
  productIngredientId: number,
) =>
  axios.delete(
    `/admin/productIngredient/${productIngredientId}`,
  );

const useProductIngredients = (productId: number) =>
  useQuery(['productIngredients', productId], () =>
    getProductIngredients(productId),
  );
const useProductIngredient = (
  productIngredientId: number,
  enabled: boolean,
) =>
  useQuery(
    ['productIngredient', productIngredientId],
    () => getProductIngredient(productIngredientId),
    { enabled },
  );
const useProductIngredientsAvailable = (
  productId: number,
  enabled: boolean,
) =>
  useQuery(
    ['productIngredientsAvailable', productId],
    () => getProductIngredientsAvailable(productId),
    { enabled },
  );

const mutateProductIngredient = makeMutation(
  ['/productIngredients'],
  postProductIngredient,
  ['productIngredients', 'productIngredientsAvailable'],
);
const mutateProductIngredientUpdate = makeMutation(
  ['/productIngredients'],
  putProductIngredient,
  ['productIngredients', 'productIngredientsAvailable'],
);
const mutateProductIngredientToProduct = makeMutation(
  ['/productIngredients'],
  postProductIngredientToProduct,
  ['productIngredients', 'productIngredientsAvailable'],
);
const mutateProductIngredientFromProduct = makeMutation(
  ['/productIngredients'],
  deleteProductIngredientFromProduct,
  ['productIngredients', 'productIngredientsAvailable'],
);
const mutateProductIngredientDelete = makeMutation(
  ['/productIngredients'],
  deleteProductIngredient,
  ['productIngredients', 'productIngredientsAvailable'],
);

export {
  mutateProductIngredient,
  mutateProductIngredientDelete,
  mutateProductIngredientFromProduct,
  mutateProductIngredientToProduct,
  mutateProductIngredientUpdate,
  useProductIngredient,
  useProductIngredients,
  useProductIngredientsAvailable,
};
