import { Card } from 'flowbite-react';

import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

import {
  makeAllOrderCompleted,
  useOrdersAccepted,
} from '@/api/orders';
import Skeleton from '@/components/Skeleton';
import OrderDetails from '@/features/orders/OrderDetails';
import OnProcessingActions from './actions/OnProcessingActions';

function OrdersOnProcessing() {
  const ordersCompleted = useOrdersAccepted();
  const onProcessingOrders = makeAllOrderCompleted();

  const orders = ordersCompleted.data?.results ?? [];

  return (
    <div className="print:hidden">
      <div className="flex justify-between items-center">
        <h2>Pedidos por entregar</h2>
        {orders.length > 0 && (
          <button
            type="button"
            onClick={() => {
              onProcessingOrders.mutate({});
            }}
            className="flex gap-1 items-center border-2 border-primary p-1 rounded-md text-white bg-main transition-colors hover:text-primary hover:bg-white">
            <IoMdCheckmarkCircleOutline /> Marcar todos
            entregados
          </button>
        )}
      </div>

      {(onProcessingOrders.isLoading ||
        ordersCompleted.isLoading) && (
        <div className="my-2">
          <Skeleton />
        </div>
      )}

      {!ordersCompleted.isLoading &&
        !onProcessingOrders.isLoading &&
        orders.length === 0 && (
          <div className="h-32 grid place-items-center">
            <div className="flex justify-center items-center text-3xl text-gray-500 gap-2 [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
              <IoMdCheckmarkCircleOutline />
              No hay pedidos por entregar.
            </div>
          </div>
        )}

      {!ordersCompleted.isLoading &&
        orders.length > 0 &&
        orders.map((order: Api.Order) => (
          <Card key={order.id} className="my-2">
            <OrderDetails order={order} />
            <OnProcessingActions order={order} />
          </Card>
        ))}
    </div>
  );
}

export default OrdersOnProcessing;
