import { getRoleClasses, getRoleName } from '@/utils/roles';
import cx from 'classnames';
import { Badge, Table } from 'flowbite-react';
import moment from 'moment';
import { useNavigate } from 'react-router';

function UsersTable({ users }: { users: Api.User[] }) {
  const navigate = useNavigate();

  return (
    <Table className="min-w-full divide-y divide-gray-200 ">
      <Table.Head className="bg-gray-100 ">
        <Table.HeadCell>Id</Table.HeadCell>
        <Table.HeadCell>Nombre</Table.HeadCell>
        <Table.HeadCell>Email</Table.HeadCell>
        <Table.HeadCell>Roles</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Registered</Table.HeadCell>
        <Table.HeadCell />
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white  ">
        {users.length === 0 && (
          <Table.Row className="hover:bg-gray-100 ">
            <Table.Cell
              colSpan={7}
              className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              No hay Pedidos
            </Table.Cell>
          </Table.Row>
        )}
        {users.map((user) => (
          <Table.Row
            className="hover:bg-gray-100  cursor-pointer"
            key={user.id}
            onClick={() => {
              navigate(`/users/${user.id}`, {
                state: { user },
              });
            }}>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {user.id}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {user.firstName} {user.lastName}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {user.email}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              <div className="flex gap-2">
                {user.roles.map((role) => (
                  <Badge
                    className={cx(getRoleClasses(role))}
                    key={`${user.id}-${role}`}>
                    {getRoleName(role)}
                  </Badge>
                ))}
              </div>
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              <div className="flex gap-2 items-center">
                <div
                  className={cx(
                    'h-3 w-3 rounded-full border border-white',
                    {
                      'bg-green-300':
                        user.status === 'ACTIVE',
                    },
                    {
                      'bg-red-300':
                        user.status === 'INACTIVE',
                    },
                  )}
                />
                <div className="text-xs">{user.status}</div>
              </div>
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {moment(user.createdAt).format('DD/MM/YYYY')}
            </Table.Cell>

            <Table.Cell>&nbsp;</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default UsersTable;
