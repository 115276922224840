import { Button, TextInput } from 'flowbite-react';
import { useState } from 'react';

import Spinner from '@/components/Spinner';

type ProductIngredientLimitFormProps = {
  callback: (
    values: Pick<
      Api.ProductIngredientMaxOption,
      'maxOptions'
    >,
  ) => void;
  initialState: Partial<Api.ProductIngredientMaxOption>;
  isLoading: boolean;
};

function ProductIngredientLimitForm({
  callback,
  initialState,
  isLoading,
}: ProductIngredientLimitFormProps) {
  const [maxOptions, setMaxOptions] = useState(
    initialState.maxOptions ?? 0,
  );
  const [formErrors, setFormErrors] = useState<
    Record<string, string>
  >({});

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    if (!maxOptions || maxOptions < 1) {
      setFormErrors({
        maxOptions: 'El límite de opciones es obligatorio',
      });
      return;
    }
    setFormErrors({});
    callback({ maxOptions });
  };

  return (
    <form onSubmit={handleSubmit}>
      <table className="w-full">
        <thead>
          <th className="text-base pr-2 text-left">
            Tamaño
          </th>
          <th
            className="text-base pr-2 text-left"
            colSpan={2}>
            Porciones
          </th>
        </thead>
        <tbody className="divide-y">
          <tr>
            <td className="text-base pr-2">
              {initialState.productSize?.name}
            </td>
            <td className="text-base pr-2">
              <TextInput
                type="number"
                id="maxOptions"
                name="maxoptions"
                className="min-w-[8rem]"
                value={maxOptions}
                onChange={(e) => {
                  setMaxOptions(Number(e.target.value));
                }}
                color={
                  formErrors.maxOptions ? 'failure' : ''
                }
                helperText={
                  formErrors.maxOptions ? (
                    <small className="text-red-500">
                      {formErrors.maxOptions}
                    </small>
                  ) : (
                    ''
                  )
                }
              />
            </td>
            <td className="text-base pr-2 w-full">
              <Button
                color="main"
                type="submit"
                disabled={isLoading}>
                Guardar
                {isLoading && (
                  <Spinner size="sm" className="ml-2" />
                )}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
}

export default ProductIngredientLimitForm;
