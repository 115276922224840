import { Dropdown } from 'flowbite-react';

import {
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
} from 'react-icons/hi';
import { Link } from 'react-router-dom';

export type PaginationProps = {
  pagination: Api.Response<unknown>;
  query: Record<string, unknown>;
};

export function Pagination({
  pagination,
  query,
}: PaginationProps) {
  const pageSizeOptions = [50, 100];

  const hasPreviousPage = pagination.page > 1;
  const hasNextPage =
    pagination.page < pagination.totalPages;

  const handlePageSizeChanged = (limit: number) => {
    window.location.search = new URLSearchParams({
      ...query,
      limit: String(limit),
    }).toString();
  };

  if (pagination.totalItems === 0) return null;

  return (
    <div className="sticky right-0 bottom-0 w-full items-center border-t border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex sm:justify-between">
      <div className="mb-4 flex items-center sm:mb-0">
        {hasPreviousPage ? (
          <Link
            to={{
              search: new URLSearchParams({
                ...query,
                page: String(pagination.page - 1),
              }).toString(),
            }}
            className="inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <span className="sr-only">Previous page</span>
            <HiChevronLeft className="text-2xl" />
          </Link>
        ) : (
          <button
            type="button"
            disabled
            className="inline-flex justify-center rounded p-1 text-gray-200  dark:text-gray-600">
            <span className="sr-only">Previous page</span>
            <HiChevronLeft className="text-2xl" />
          </button>
        )}
        {hasNextPage ? (
          <Link
            to={{
              search: new URLSearchParams({
                ...query,
                page: String(pagination.page + 1),
              }).toString(),
            }}
            className="mr-2 inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <span className="sr-only">Next page</span>
            <HiChevronRight className="text-2xl" />
          </Link>
        ) : (
          <button
            type="button"
            disabled
            className="mr-2 inline-flex justify-center rounded p-1 text-gray-200  dark:text-gray-600">
            <span className="sr-only">Next page</span>
            <HiChevronRight className="text-2xl" />
          </button>
        )}
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          Mostrando &nbsp;
          <span className="font-semibold text-gray-900 dark:text-white">
            {((pagination.page ?? 1) - 1) *
              (pagination.limit ?? 0) +
              1}
            {' de '}
            {(pagination.page ?? 0) *
              (pagination.limit ?? 0)}
          </span>
          {' de '}
          <span className="font-semibold text-gray-900 dark:text-white">
            {pagination.totalItems ?? 0}
          </span>
          &nbsp; resultados
        </span>
      </div>
      <div className="flex items-center space-x-3">
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          Mostrar
        </span>
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <span className="border p-1 text-sm rounded flex gap-1 dark:border-gray-700">
              {pagination.limit ?? pageSizeOptions[0]}
              <HiChevronDown className="text-xl ml-1" />
            </span>
          }>
          {pageSizeOptions.map((option) => (
            <Dropdown.Item
              key={option}
              onClick={() => {
                handlePageSizeChanged(option);
              }}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </div>
    </div>
  );
}

export default Pagination;
