import ProductIngredientLimitTable from '../productIngredientLimit/ProductIngredientLimitTable';
import ProductRawMaterialsListCard from '../productRawMaterial/ProductRawMaterialsListCard';

type ProductIngredientListItemProps = {
  productIngredient: Api.ProductIngredient;
};

function ProductIngredientListItem({
  productIngredient,
}: ProductIngredientListItemProps) {
  return (
    <li className="pb-6 mb-4 border-gray-100 border-b">
      <div className="flex gap-4">
        <ProductRawMaterialsListCard
          title={productIngredient.name}
          productRawMaterials={
            productIngredient.productRawMaterials
          }
          displayCreateButton={false}
        />
        <ProductIngredientLimitTable
          productIngredient={productIngredient}
        />
      </div>
    </li>
  );
}

export default ProductIngredientListItem;
