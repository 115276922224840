import { Card } from 'flowbite-react';
import { Fragment, useState } from 'react';

import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

import { useOrdersPending } from '@/api/orders';
import QueryResult from '@/components/QueryResult';
import OrderDetails from '@/features/orders/OrderDetails';
import OrderDetailsPrint from '@/features/orders/OrderDetailsPrint';
import OnHoldActions from './actions/OnHoldActions';

function OrdersOnHold() {
  const onHoldOrders = useOrdersPending();
  const [idPrinting, setIdPrinting] = useState<
    null | number
  >(null);

  return (
    <div>
      <h2 className="print:hidden">Pedidos por pagar</h2>
      <QueryResult query={onHoldOrders}>
        {onHoldOrders.data?.results &&
          onHoldOrders.data.results.length > 0 &&
          onHoldOrders.data.results.map((order) => (
            <Fragment key={`order_${order.id}`}>
              <Card className="my-2 print:hidden">
                <OrderDetails order={order} />
                <OnHoldActions
                  order={order}
                  onSuccess={(orderId: number) => {
                    setIdPrinting(orderId);
                  }}
                />
              </Card>
              <OrderDetailsPrint
                order={order}
                isPrinting={idPrinting === order.id}
              />
            </Fragment>
          ))}
        {onHoldOrders.data?.results &&
          onHoldOrders.data.results.length === 0 && (
            <div className="h-32 grid place-items-center print:hidden">
              <div className="flex justify-center items-center text-3xl text-gray-500 gap-2 [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
                <IoMdCheckmarkCircleOutline />
                No hay pedidos en espera.
              </div>
            </div>
          )}
      </QueryResult>
    </div>
  );
}

export default OrdersOnHold;
