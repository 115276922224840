import { Breadcrumb } from 'flowbite-react';
import { useNavigate, useParams } from 'react-router';

import { HiHome } from 'react-icons/hi';

import {
  mutateProductUpdate,
  useProduct,
} from '@/api/products';
import QueryResult from '@/components/QueryResult';
import { toastMsg } from '@/components/Toast';
import ProductProvider from '@/context/product/ProductProvider';
import ProductForm from '@/features/products/ProductForm';
import ProductVariations from '@/features/products/ProductVariations';
import { formatCentsToEuro } from '@/helpers/money';
import NavbarSidebar from '@/layouts/NavbarSidebar';

function ProductPageUpdate() {
  const { id } = useParams();
  const mutate = mutateProductUpdate();
  const navigate = useNavigate();

  const productId = Number(id);
  const product = useProduct(productId);

  if (!productId) {
    navigate('/products');
  }

  const handleUpdateProduct = async (
    productDetails: Partial<Api.Product>,
  ) => {
    await mutate
      .mutateAsync({ ...productDetails, id: productId })
      .then(() => {
        toastMsg({ title: 'Producto actualizado' });
      });
  };

  return (
    <NavbarSidebar>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4   sm:flex">
        <div className="w-full">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <div className="flex items-center gap-x-3">
                <HiHome className="text-xl" />
                <span className="">Inicio</span>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/products">
              Productos
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Editar Producto
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <QueryResult query={product}>
        {product.data && (
          <ProductProvider product={product.data}>
            <div className="flex flex-col">
              <div className="overflow-x-auto">
                <div className="relative grid grid-cols-1 overflow-y-hidden xl:h-[calc(100vh-4rem)] xl:grid-cols-3">
                  <div className="py-6 px-4 xl:sticky xl:mb-0 xl:pb-0">
                    <div className="mb-4 rounded-lg bg-white p-4 shadow  sm:p-6 xl:p-8">
                      <h1 className="text-xl font-semibold text-gray-900  sm:text-2xl">
                        Informaci&oacute;n del Producto
                      </h1>
                      {product.data && (
                        <ProductForm
                          callback={handleUpdateProduct}
                          initialState={{
                            ...product.data,
                            basePrice: formatCentsToEuro(
                              product.data.basePrice,
                            ),
                          }}
                          isLoading={mutate.isLoading}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full col-span-2 m-auto mb-5 h-full space-y-6 overflow-hidden overflow-y-auto p-4 lg:pt-6">
                    <ProductVariations />
                  </div>
                </div>
              </div>
            </div>
          </ProductProvider>
        )}
      </QueryResult>
    </NavbarSidebar>
  );
}

export default ProductPageUpdate;
