import formatMoney from '../../helpers/money';

type DaySummaryItemsProps = {
  productsSold: Record<number, Api.ProductsSold>;
};

function DaySummaryItems({
  productsSold,
}: DaySummaryItemsProps) {
  const productKeys = Object.keys(productsSold);

  if (productKeys.length === 0) {
    return (
      <tr>
        <td colSpan={2}>No se han vendido productos</td>
      </tr>
    );
  }

  return (
    <>
      {productKeys.map((productId) => {
        const product = productsSold[Number(productId)];

        if (!product) {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
        }

        return (
          <tr key={productId}>
            <td>
              <div className="flex gap-2 items-center my-2">
                {product.imageUrl && (
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    className="w-12 h-12"
                  />
                )}
                {product.name}
              </div>
            </td>
            <td>{product.quantity}</td>
            <td>{formatMoney(product.total)}</td>
          </tr>
        );
      })}
    </>
  );
}

export default DaySummaryItems;
