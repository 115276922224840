import { useQuery } from 'react-query';
import { axios } from './axios';

import makeMutation from '@/hooks/makeMutation';

const getProductSizes = (
  productId: number,
): Promise<Api.Response<Api.ProductSize[]>> =>
  axios.get(`/admin/products/${productId}/productSize`);
const postProductSize = (
  productSize: Omit<Api.ProductSize, 'id'> & {
    id: number;
    productId: number;
  },
) => axios.post('/admin/productSize', productSize);
const putProductSize = (
  productSize: Partial<Api.ProductSize>,
) =>
  axios.put(
    `/admin/productSize/${productSize.id}`,
    productSize,
  );
const deleteProductSize = (id: number) =>
  axios.delete(`/admin/productSize/${id}`);

const useProductSizes = (productId: number) =>
  useQuery(['productSizes', productId], () =>
    getProductSizes(productId),
  );
const mutateProductSize = makeMutation(
  ['/productSizes'],
  postProductSize,
  ['productSizes'],
);
const mutateProductSizeUpdate = makeMutation(
  ['/productSizes'],
  putProductSize,
  ['productSizes'],
);

const mutateProductSizeDelete = makeMutation(
  ['/productSizes'],
  deleteProductSize,
  ['productSizes'],
);

export {
  mutateProductSize,
  mutateProductSizeDelete,
  mutateProductSizeUpdate,
  useProductSizes,
};
