import { ToggleSwitch } from 'flowbite-react';
import { useState } from 'react';

import {
  mutateProductIngredientFromProduct,
  mutateProductIngredientToProduct,
} from '@/api/ingredients';
import { toastErrRequest } from '@/components/Toast';
import useProductCtx from '@/hooks/useProductCtx';
import ProductRawMaterialsListCard from '../productRawMaterial/ProductRawMaterialsListCard';
import ProductIngredientModalUpdate from './modals/ProductIngredientModalUpdate';

type ProductIngredientDetailedProps = {
  productIngredient: Api.ProductIngredientsAvailable;
};

function ProductIngredientDetailed({
  productIngredient,
}: ProductIngredientDetailedProps) {
  const { productId, product } = useProductCtx();
  const [isUsed, setIsUsed] = useState(
    productIngredient.isUsed,
  );

  const productIngredientToProduct =
    mutateProductIngredientToProduct();
  const productIngredientFromProduct =
    mutateProductIngredientFromProduct();

  const mutateProductIngredient = async (
    productIsUsed: boolean,
  ) => {
    if (productIsUsed) {
      await productIngredientFromProduct
        .mutateAsync({
          productId,
          productIngredientId: productIngredient.id,
        })
        .catch((error: unknown) => {
          throw error;
        });
    } else {
      await productIngredientToProduct
        .mutateAsync({
          productId,
          productIngredientId: productIngredient.id,
        })
        .catch((error: unknown) => {
          throw error;
        });
    }
  };

  const handleUpdateProduct = () => {
    mutateProductIngredient(isUsed)
      .then(() => {
        setIsUsed(!isUsed);
      })
      .catch((error: unknown) => {
        toastErrRequest(error);
      });
  };

  if (!productIngredient) {
    return null;
  }

  return (
    <>
      <div className="bg-gray-50 p-4 rounded-md w-full">
        <div className="flex gap-4 items-center">
          <h3 className="text-xl">
            {productIngredient.name}
          </h3>
          <ProductIngredientModalUpdate
            key={productIngredient.id}
            productIngredientId={productIngredient.id}
          />
        </div>
        <div className="my-4">
          <h3 className="text-lg">
            Orden:
            <small className="ml-1 bg-main px-1 text-white rounded-sm">
              {productIngredient.order}
            </small>
          </h3>
        </div>
        <ToggleSwitch
          checked={isUsed}
          label={`Asignado a ${product.name}`}
          onChange={handleUpdateProduct}
        />
      </div>
      <ProductRawMaterialsListCard
        title="Materia Prima"
        productRawMaterials={
          productIngredient.productRawMaterials
        }
        displayOrder
        displayCreateButton
        ingredientId={productIngredient.id}
      />
    </>
  );
}

export default ProductIngredientDetailed;
