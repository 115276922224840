enum Role {
  ADMIN = 'ADMIN',
  FRANCHISE_OWNER = 'FRANCHISE_OWNER',
  FRANCHISE_EMPLOYEE = 'FRANCHISE_EMPLOYEE',
  CUSTOMER = 'CUSTOMER',
}

const getRoleName = (role: Role) => {
  switch (role) {
    case Role.ADMIN:
      return 'Admin';
    case Role.FRANCHISE_OWNER:
      return 'Franchise Owner';
    case Role.FRANCHISE_EMPLOYEE:
      return 'Franchise Employee';
    case Role.CUSTOMER:
      return 'Customer';
  }
};

const getRoleClasses = (role: Role) => {
  switch (role) {
    case Role.ADMIN:
      return 'bg-gray-100 text-gray-800';
    case Role.FRANCHISE_OWNER:
      return 'bg-indigo-100 text-indigo-800';
    case Role.FRANCHISE_EMPLOYEE:
      return 'bg-purple-100 text-purple-800';
    case Role.CUSTOMER:
      return 'bg-green-100 text-green-800';
  }
};

export { getRoleClasses, getRoleName };
