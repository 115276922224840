import cx from 'classnames';
import { ListGroup } from 'flowbite-react';
import { useEffect, useState } from 'react';

import { VscCircleLarge, VscPass } from 'react-icons/vsc';

import useProductCtx from '@/hooks/useProductCtx';
import ProductIngredientDetailed from './ProductIngredientDetailed';
import ProductIngredientModalCreate from './modals/ProductIngredientModalCreate';
import ProductIngredientModalDelete from './modals/ProductIngredientModalDelete';

type ProductIngredientsAdminProps = {
  productIngredientsAvailable: Api.ProductIngredientsAvailable[];
};
function ProductIngredientsAdmin({
  productIngredientsAvailable,
}: ProductIngredientsAdminProps) {
  const {
    productIngredientDisplayed,
    setProductIngredientDisplayed,
  } = useProductCtx();
  const [
    productIngredientIdToDisplay,
    setProductIngredientIdToDisplay,
  ] = useState<number | null>(null);

  const handleDisplayProductIngredient = (
    productIngredient: Api.ProductIngredientsAvailable,
  ) => {
    setProductIngredientDisplayed(productIngredient);
  };

  const handleResetProductIngredientDisplayed = () => {
    setProductIngredientDisplayed(
      productIngredientsAvailable[0],
    );
  };

  useEffect(() => {
    if (
      productIngredientsAvailable.length &&
      !productIngredientDisplayed.id
    ) {
      setProductIngredientDisplayed(
        productIngredientsAvailable[0],
      );
    }
  }, []);

  useEffect(() => {
    if (productIngredientIdToDisplay) {
      const newProductIngredientDisplayed =
        productIngredientsAvailable.find(
          (productIngredient) =>
            productIngredient.id ===
            productIngredientIdToDisplay,
        );
      if (newProductIngredientDisplayed) {
        setProductIngredientDisplayed(
          newProductIngredientDisplayed,
        );
      }
      setProductIngredientIdToDisplay(null);
      return;
    }
    const newProductIngredientDisplayed =
      productIngredientsAvailable.find(
        (productIngredient) =>
          productIngredient.id ===
          productIngredientDisplayed.id,
      );
    if (newProductIngredientDisplayed) {
      //  && productIngredientDisplayed.updatedAt !== newProductIngredientDisplayed.updatedAt
      setProductIngredientDisplayed(
        newProductIngredientDisplayed,
      );
    }
  }, [productIngredientsAvailable]);

  return (
    <div className="flex gap-6">
      <div className="flex flex-col gap-4">
        <ListGroup className="w-48">
          {productIngredientsAvailable.map(
            (productIngredient) => (
              <ListGroup.Item
                key={productIngredient.id}
                onClick={() => {
                  handleDisplayProductIngredient(
                    productIngredient,
                  );
                }}
                active={
                  productIngredientDisplayed.id ===
                  productIngredient.id
                }>
                <div className="flex gap-1 items-center">
                  {productIngredient.isUsed ? (
                    <VscPass
                      className={cx(
                        'text-xl',
                        productIngredientDisplayed.id ===
                          productIngredient.id
                          ? 'text-white'
                          : 'text-green-500',
                      )}
                    />
                  ) : (
                    <VscCircleLarge
                      className={cx(
                        'text-xl',
                        productIngredientDisplayed.id ===
                          productIngredient.id
                          ? 'text-white'
                          : 'text-gray-500',
                      )}
                    />
                  )}
                  {productIngredient.name}
                </div>
              </ListGroup.Item>
            ),
          )}
        </ListGroup>
        <ProductIngredientModalCreate
          callback={setProductIngredientIdToDisplay}
        />
        <ProductIngredientModalDelete
          productIngredientId={
            productIngredientDisplayed.id
          }
          callback={handleResetProductIngredientDisplayed}
        />
      </div>
      {productIngredientDisplayed.id && (
        <ProductIngredientDetailed
          key={`${productIngredientDisplayed.id}-${productIngredientDisplayed.updatedAt}`}
          productIngredient={productIngredientDisplayed}
        />
      )}
    </div>
  );
}

export default ProductIngredientsAdmin;
