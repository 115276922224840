import cx from 'classnames';
import { Badge, Table } from 'flowbite-react';
import { useNavigate } from 'react-router';

import formatMoney from '@/helpers/money';
import validatePaymentMethod from '@/helpers/paymentMethod';

function OrdersTable({ orders }: { orders: Api.Order[] }) {
  const navigate = useNavigate();

  return (
    <Table className="min-w-full divide-y divide-gray-200 ">
      <Table.Head className="bg-gray-100 ">
        <Table.HeadCell>Id</Table.HeadCell>
        <Table.HeadCell># Pedido del dia</Table.HeadCell>
        <Table.HeadCell>Cliente</Table.HeadCell>
        <Table.HeadCell>Tienda</Table.HeadCell>
        <Table.HeadCell>Pago</Table.HeadCell>
        <Table.HeadCell>Total</Table.HeadCell>
        <Table.HeadCell>Estatus</Table.HeadCell>
        <Table.HeadCell />
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white  ">
        {orders.length === 0 && (
          <Table.Row className="hover:bg-gray-100 ">
            <Table.Cell
              colSpan={7}
              className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              No hay Pedidos
            </Table.Cell>
          </Table.Row>
        )}
        {orders.map((order) => (
          <Table.Row
            className="hover:bg-gray-100  cursor-pointer"
            key={order.id}
            onClick={() => {
              navigate(`/orders/${order.id}`, {
                state: { order },
              });
            }}>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {order.id}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {order.dailyOrderNumber}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {order.user.firstName} {order.user.lastName}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {order.store.name}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {validatePaymentMethod(order.paymentMethod)}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {formatMoney(order.total)}
            </Table.Cell>
            <Table.Cell>
              <div className="flex">
                <Badge
                  className={cx(
                    'text-white',
                    order.status === 'PENDING' &&
                      'bg-yellow-400',
                    order.status === 'DELIVERED' &&
                      'bg-green-400',
                    order.status === 'ACCEPTED' &&
                      'bg-blue-400',
                    order.status === 'REJECTED' &&
                      'bg-red-400',
                    order.status === 'CANCELLED' &&
                      'bg-gray-400',
                  )}>
                  {order.status}
                </Badge>
              </div>
            </Table.Cell>
            <Table.Cell>&nbsp;</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default OrdersTable;
