import { useQuery } from 'react-query';
import axios from './axios';

const getUsers = (
  args: UserQuery,
): Promise<Api.Response<Api.User[]>> =>
  axios.get('/admin/users', { params: args });

const useUsers = (args: UserQuery) =>
  useQuery(['users', args], () => getUsers(args));

export { useUsers };
