import { createContext } from 'react';

export type ProductContextInterface = {
  productId: number;
  product: Api.Product;
  productIngredientDisplayed: Api.ProductIngredientsAvailable;
  setProductIngredientDisplayed: (
    productIngredient: Api.ProductIngredientsAvailable,
  ) => void;
  productSizes: Api.ProductSize[];
  setProductSizes: (
    productSizes: Api.ProductSize[],
  ) => void;
  productIngredients: Api.ProductIngredient[];
  setProductIngredients: (
    productIngredients: Api.ProductIngredient[],
  ) => void;
  lastTimeEdited: Date;
  setLastTimeEdited: (date: Date) => void;
};

const productContextDefaults: ProductContextInterface = {
  productId: 0,
  product: {} as Api.Product,
  productIngredientDisplayed:
    {} as Api.ProductIngredientsAvailable,
  setProductIngredientDisplayed: () => null,
  productSizes: [],
  setProductSizes: () => null,
  productIngredients: [],
  setProductIngredients: () => null,
  lastTimeEdited: new Date(),
  setLastTimeEdited: () => null,
};

export const ProductContext =
  createContext<ProductContextInterface>(
    productContextDefaults,
  );
