import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import ProductRawMaterialForm from '../ProductRawMaterialForm';

import { mutateProductRawMaterial } from '@/api/rawMaterials';
import {
  toastErrRequest,
  toastMsg,
} from '@/components/Toast';
import useProductCtx from '@/hooks/useProductCtx';

type ProductRawMaterialModalCreateProps = {
  ingredientId: number;
};

function ProductRawMaterialModalCreate({
  ingredientId,
}: ProductRawMaterialModalCreateProps) {
  const { productId } = useProductCtx();
  const [isOpen, setOpen] = useState(false);
  const [initialState, setInitialState] = useState<
    Partial<Api.ProductRawMaterial>
  >({});
  const [formKey, setFormKey] = useState(0);

  const mutate = mutateProductRawMaterial();

  const handleCloseModal = () => {
    setOpen(false);
    setInitialState({});
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleCreateProductRawMaterial = (
    productRawMaterial: Omit<Api.ProductRawMaterial, 'id'>,
  ) => {
    mutate
      .mutateAsync({
        ...productRawMaterial,
        productId,
        ingredientId,
      })
      .then(() => {
        toastMsg({ title: 'Tamaño creado' });
        handleCloseModal();
      })
      .catch((reason: unknown) => {
        toastErrRequest(reason);
      });
  };

  return (
    <div className="mt-4">
      <Button
        onClick={() => {
          setOpen(true);
        }}
        size="xs"
        className="mx-auto">
        Agregar Materia Prima
      </Button>
      <Modal onClose={handleCloseModal} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Agregar Materia Prima</strong>
        </Modal.Header>
        <Modal.Body>
          <ProductRawMaterialForm
            key={formKey}
            callback={handleCreateProductRawMaterial}
            initialState={initialState}
            isLoading={mutate.isLoading}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProductRawMaterialModalCreate;
