import { Card, TextInput } from 'flowbite-react';
import moment from 'moment';
import { useState } from 'react';

import DaySummary from '@/features/summary/DaySummary';
import NavbarSidebar from '@/layouts/NavbarSidebar';

function ResumePage() {
  const [date, setDate] = useState<Date>(
    moment().utc().toDate(),
  );
  return (
    <NavbarSidebar>
      <div className="px-4 pt-6">
        <Card>
          <h1 className="text-2xl font-semibold">
            Resumen de día
          </h1>
          <TextInput
            type="date"
            value={date.toISOString().split('T')[0]}
            onChange={(e) => {
              setDate(
                moment(e.target.value).utc().toDate(),
              );
            }}
            max={
              moment()
                .utc()
                .toDate()
                .toISOString()
                .split('T')[0]
            }
          />
          <DaySummary date={date} />
        </Card>
      </div>
    </NavbarSidebar>
  );
}

export default ResumePage;
