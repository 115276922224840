import { useQuery } from 'react-query';
import { axios } from './axios';

import makeMutation from '@/hooks/makeMutation';

type InventoryItem = {
  itemId: number;
  storeId: number;
  quantity: number;
};

const getInventory = (
  id: number,
): Promise<Api.Response<Api.Inventory[]>> =>
  axios.get(`/admin/stores/${id}/inventory`);
const putInventoryItem = (inventoryItem: InventoryItem) =>
  axios.put(
    `/admin/stores/${inventoryItem.storeId}/inventory/${inventoryItem.itemId}`,
    {
      quantity: inventoryItem.quantity,
    },
  );

const useInventory = (id: number) =>
  useQuery('inventory', () => getInventory(id));
const updateInventoryItem = makeMutation(
  'inventory/putInventoryItem',
  putInventoryItem,
  ['inventory'],
);

export { updateInventoryItem, useInventory };
