import cx from 'classnames';
import {
  Badge,
  Breadcrumb,
  Dropdown,
} from 'flowbite-react';
import moment from 'moment';
import { useState } from 'react';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';

import { HiHome } from 'react-icons/hi';

import { useOrders } from '@/api/orders';
import Pagination from '@/components/Pagination';
import QueryResult from '@/components/QueryResult';
import OrdersTable from '@/features/orders/OrdersTable';
import NavbarSidebar from '@/layouts/NavbarSidebar';

function OrdersPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<Date>(
    moment().utc().subtract(7, 'days').toDate(),
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().utc().toDate(),
  );

  const key = (
    ['createdAt', 'total'].includes(
      searchParams.get('key') ?? '',
    )
      ? searchParams.get('key')
      : 'createdAt'
  ) as OrderQuery['key'];

  const direction = (
    ['asc', 'desc'].includes(
      searchParams.get('direction') ?? '',
    )
      ? searchParams.get('direction')
      : 'desc'
  ) as OrderQuery['direction'];

  const page = Number.isNaN(
    Number(searchParams.get('page')),
  )
    ? 1
    : Number(searchParams.get('page') ?? 1);

  const limit = Number.isNaN(
    Number(searchParams.get('limit') ?? 50),
  )
    ? 10
    : Number(searchParams.get('limit') ?? 50);

  const statusList = [
    'all',
    'PENDING',
    'ACCEPTED',
    'REJECTED',
    'CANCELLED',
    'DELIVERED',
  ];

  const status = (
    statusList.includes(searchParams.get('status') ?? '')
      ? searchParams.get('status')
      : 'all'
  ) as OrderQuery['status'];

  const orders = useOrders({
    key,
    direction,
    page,
    limit,
    status,
    startDate: startDate.getTime(),
    endDate: endDate.getTime(),
  });

  return (
    <NavbarSidebar>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4   sm:flex">
        <div className="w-full">
          <div className="flex justify-between items-center gap-40">
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="">Inicio</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Pedidos</Breadcrumb.Item>
            </Breadcrumb>
            <div className="flex w-full items-center sm:justify-end gap-4">
              <Datepicker
                i18n="es"
                primaryColor="blue"
                maxDate={new Date()}
                value={{
                  startDate,
                  endDate,
                }}
                onChange={(newValue) => {
                  if (!newValue) return;
                  setStartDate(
                    moment(
                      newValue.startDate?.toString() ?? '',
                    )
                      .utc()
                      .toDate(),
                  );
                  // the selected end date should be at midnight of the current day
                  setEndDate(
                    moment(
                      newValue.endDate?.toString() ?? '',
                    )
                      .utc()
                      .endOf('day')
                      .toDate(),
                  );
                }}
              />

              <Dropdown
                label={
                  <div className="flex items-center gap-2">
                    <Badge
                      className={cx(
                        'text-white w-4 h-4 !block !p-0',
                        status === 'PENDING' &&
                          'bg-yellow-400',
                        status === 'DELIVERED' &&
                          'bg-green-400',
                        status === 'ACCEPTED' &&
                          'bg-blue-400',
                        status === 'REJECTED' &&
                          'bg-red-400',
                        status === 'CANCELLED' &&
                          'bg-gray-400',
                      )}>
                      &nbsp;
                    </Badge>
                    <span className="text-sm">Estatus</span>
                  </div>
                }
                inline>
                {statusList.map((s) => (
                  <Dropdown.Item
                    key={s}
                    onClick={() => {
                      navigate(
                        `/orders?key=${key}&direction=${direction}&page=${page}&limit=${limit}&status=${s}`,
                      );
                    }}>
                    <div className="flex items-center gap-2">
                      <Badge
                        className={cx(
                          'text-white w-4 h-4 !block !p-0',
                          s === 'PENDING' &&
                            'bg-yellow-400',
                          s === 'DELIVERED' &&
                            'bg-green-400',
                          s === 'ACCEPTED' && 'bg-blue-400',
                          s === 'REJECTED' && 'bg-red-400',
                          s === 'CANCELLED' &&
                            'bg-gray-400',
                        )}>
                        &nbsp;
                      </Badge>
                      <span className="text-sm uppercase">
                        {s}
                      </span>
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>
          </div>
          <div className="block items-center sm:flex">
            <div className="flex w-full items-center sm:justify-end" />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <QueryResult query={orders}>
          <OrdersTable
            orders={orders.data?.results ?? []}
          />
          {orders.data && (
            <Pagination
              pagination={orders.data}
              query={{
                key,
                direction,
                page,
                limit,
                status,
              }}
            />
          )}
        </QueryResult>
      </div>
    </NavbarSidebar>
  );
}

export default OrdersPage;
