import { Label } from 'flowbite-react';

import useStoreCtx from '@/hooks/useStoreCtx';
import AdminModalDisable from './users/AdminModalDisable';
import AdminModalUpdate from './users/AdminModalUpdate';

function AdminStoreList() {
  const { store } = useStoreCtx();

  return (
    <ul className="mt-2">
      {store?.admins.map((admin) => (
        <li
          key={admin.id}
          className="flex gap-2 items-center hover:bg-gray-50 p-2">
          <div className="w-8 h-8 min-w-[2rem] bg-main text-white rounded-full grid place-items-center">
            {admin.firstName.charAt(0)}
            {admin.lastName.charAt(0)}
          </div>
          <div className="w-full">
            <Label>{`${admin.firstName} ${admin.lastName}`}</Label>
          </div>
          <div className="flex gap-2">
            <AdminModalUpdate />
            <AdminModalDisable />
          </div>
          <div />
        </li>
      ))}
    </ul>
  );
}

export default AdminStoreList;
