import ProductIngredientListItem from './ProductIngredientListItem';

type ProductIngredientListProps = {
  productIngredients: Api.ProductIngredient[];
};

function ProductIngredientList({
  productIngredients,
}: ProductIngredientListProps) {
  if (productIngredients.length === 0) {
    return (
      <small className="my-4">
        No hay ingredientes asignados a este producto.
      </small>
    );
  }
  return (
    <ul className="my-4 grid gap-4">
      {productIngredients.map((productIngredient) => (
        <ProductIngredientListItem
          key={productIngredient.id}
          productIngredient={productIngredient}
        />
      ))}
    </ul>
  );
}

export default ProductIngredientList;
