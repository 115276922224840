import { Card } from 'flowbite-react';
import moment from 'moment';
import { useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';

import SalesThisWeek from '@/features/home/SalesThisWeek';
import NavbarSidebar from '@/layouts/NavbarSidebar';

function HomePage() {
  const [startDate, setStartDate] = useState<Date>(
    moment().utc().subtract(7, 'days').toDate(),
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().utc().toDate(),
  );
  return (
    <NavbarSidebar>
      <div className="px-4 pt-6">
        <Card>
          <Datepicker
            i18n="es"
            primaryColor="blue"
            maxDate={new Date()}
            value={{
              startDate,
              endDate,
            }}
            onChange={(newValue) => {
              if (!newValue) return;
              setStartDate(
                moment(newValue.startDate?.toString() ?? '')
                  .utc()
                  .toDate(),
              );
              // the selected end date should be at midnight of the current day
              setEndDate(
                moment(newValue.endDate?.toString() ?? '')
                  .utc()
                  .endOf('day')
                  .toDate(),
              );
            }}
          />
          {startDate && endDate && (
            <>
              <p className="text-sm text-gray-500">
                {`Mostrando ventas desde ${moment(
                  startDate,
                ).format('L')} hasta ${moment(
                  endDate,
                ).format('L')}`}
              </p>
              <SalesThisWeek
                startDate={startDate.getTime()}
                endDate={endDate.getTime()}
                key={
                  startDate.toISOString() +
                  endDate.toISOString()
                }
              />
            </>
          )}
        </Card>
      </div>
    </NavbarSidebar>
  );
}

export default HomePage;
