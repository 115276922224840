import { useQuery } from 'react-query';
import { axios } from './axios';

import makeMutation from '@/hooks/makeMutation';

const getOrder = (
  orderId: number,
): Promise<Api.Response<Api.Order>> =>
  axios.get(`/admin/orders/${orderId}`);
const getOrders = (
  args: OrderQuery,
): Promise<Api.Response<Api.Order[]>> =>
  axios.get('/admin/orders', { params: args });
const putOrder = (order: Partial<Api.Order>) =>
  axios.put(`/admin/orders/${order.id}`, order);
const getOrderAccepted = (): Promise<
  Api.Response<Api.Order[]>
> => axios.get('/admin/orders?status=ACCEPTED');
const getOrderPending = (): Promise<
  Api.Response<Api.Order[]>
> =>
  axios.get(
    '/admin/orders?status=PENDING&paymentMethod=CASH_ON_DELIVERY',
  );
const postOrderSuccess = (orderId: number) =>
  axios.post(`/admin/orders/${orderId}/success`);
const postOrderCompleted = (orderId: number) =>
  axios.post(`/admin/orders/${orderId}/completed`);
const postAllOrderCompleted = () =>
  axios.post('/admin/orders/completed');
const postOrderCancel = (orderId: number) =>
  axios.post(`/admin/orders/${orderId}/cancel`);

const useOrder = (orderId: number) =>
  useQuery(['order', orderId], () => getOrder(orderId), {
    enabled: !!orderId,
  });
const useOrders = (args: OrderQuery) =>
  useQuery(['orders', args], () => getOrders(args));
const useOrdersAccepted = () =>
  useQuery(['orders/accepted'], () => getOrderAccepted());
const useOrdersPending = () =>
  useQuery(['orders/pending'], () => getOrderPending(), {
    refetchInterval: 10000,
  });
const mutateOrderUpdate = makeMutation(
  ['/order'],
  putOrder,
  ['orders', 'orders/accepted', 'orders/pending'],
);
const makeOrderSuccess = makeMutation(
  ['/order/success'],
  postOrderSuccess,
  ['orders', 'orders/accepted', 'orders/pending'],
);
const makeOrderCompleted = makeMutation(
  ['/order/completed'],
  postOrderCompleted,
  ['orders', 'orders/accepted', 'orders/pending'],
);
const makeAllOrderCompleted = makeMutation(
  ['/order/completed'],
  postAllOrderCompleted,
  ['orders', 'orders/accepted', 'orders/pending'],
);
const makeOrderCancel = makeMutation(
  ['/order/cancel'],
  postOrderCancel,
  ['orders', 'orders/accepted', 'orders/pending'],
);

export {
  makeAllOrderCompleted,
  makeOrderCancel,
  makeOrderCompleted,
  makeOrderSuccess,
  mutateOrderUpdate,
  useOrder,
  useOrders,
  useOrdersAccepted,
  useOrdersPending,
};

// Path: src/hooks/makeMutation.ts
