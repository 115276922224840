import { Label } from 'flowbite-react';

import { BsFillHouseCheckFill } from 'react-icons/bs';

import { formatOrderOutput } from '@/helpers/formatOrderOutput';
import formatMoney from '@/helpers/money';
import { formatURL } from '@/utils/url';

function OrderDetails({ order }: { order: Api.Order }) {
  return (
    <div>
      <div className="flex items-center gap-4 mb-4">
        <p className="text-3xl font-bold text-black">{`Pedido # ${order.dailyOrderNumber}`}</p>
        <p className="text-2xl font-bold text-black">
          {formatMoney(order.total)}
        </p>
        <p className="grow text-sunset text-right text-sm flex justify-end gap-1">
          <BsFillHouseCheckFill />
          Pedido Chaska
        </p>
      </div>
      <table className="w-full border-b border-gray-200">
        <thead>
          <tr className="border-b border-gray-200 uppercase text-sm font-bold">
            <th className="text-left p-2 w-1/4">
              Producto
            </th>
            <th className="text-left p-2 w-1/4">
              Cantidad
            </th>
            <th className="text-left p-2 w-2/4">
              Descripción
            </th>
          </tr>
        </thead>
        <tbody>
          {order.orderItems.map((orderItem, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr
              key={`${order.id}_${orderItem.product.id}_${i}`}
              className="border-b border-gray-200">
              <td className="p-2">
                <img
                  src={formatURL(
                    (
                      orderItem.productSize as Api.ProductSize
                    )?.imageUrl ??
                      orderItem.product.imageUrl,
                  )}
                  alt={orderItem.product.name}
                  className="w-20 h-20 object-contain m-auto"
                />
                <Label className="w-full block text-center mt-1">
                  {orderItem.product.name}
                  {orderItem.productSize &&
                    ` ${orderItem.productSize.name}`}
                  {orderItem.product.basePrice &&
                    ` - ${formatMoney(
                      orderItem.product.basePrice,
                    )}`}
                  {!orderItem.product.basePrice &&
                    orderItem.productSize.basePrice &&
                    ` - ${formatMoney(
                      orderItem.productSize.basePrice,
                    )}`}
                </Label>
              </td>
              <td>{`x ${orderItem.quantity}`}</td>
              <td className="py-2">
                {formatOrderOutput(
                  orderItem.productSteps,
                ).map((step: Record<string, string>) => (
                  <div
                    key={`${order.id}-${orderItem.id}-${JSON.stringify(step)}
                    `}>
                    <strong>{step.step}</strong>
                    <br />
                    {step.rawMaterial}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrderDetails;
