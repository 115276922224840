/* eslint-disable @typescript-eslint/no-explicit-any */

export const types = {
  setProduct: 'product/setProduct',
  setId: 'product/setId',
  setProductIngredientDisplayed:
    'product/setProductIngredientDisplayed',
  setProductSizes: 'product/setProductSizes',
  setProductIngredients: 'product/setProductIngredients',
  setLastTimeEdited: 'product/setLastTimeEdited',
};

export const initialState = {
  productId: 0,
  product: {} as Api.Product,
  productSizes: [] as Api.ProductSize[],
  productIngredients: [] as Api.ProductIngredient[],
  productIngredientDisplayed:
    {} as Api.ProductIngredientsAvailable,
  lastTimeEdited: new Date(),
};

export const productReducer = (
  state: typeof initialState,
  action: { type: string; payload?: any },
) => {
  switch (action.type) {
    case types.setProduct:
      return {
        ...state,
        product: action.payload as Api.Product,
      };
    case types.setId:
      return {
        ...state,
        productId: action.payload as number,
      };
    case types.setProductIngredientDisplayed:
      return {
        ...state,
        productIngredientDisplayed:
          action.payload as Api.ProductIngredientsAvailable,
      };
    case types.setLastTimeEdited:
      return {
        ...state,
        lastTimeEdited: action.payload as Date,
      };
    case types.setProductSizes:
      return {
        ...state,
        productSizes: action.payload as Api.ProductSize[],
      };
    case types.setProductIngredients:
      return {
        ...state,
        productIngredients:
          action.payload as Api.ProductIngredient[],
      };
    default:
      return state;
  }
};
