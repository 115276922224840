import cx from 'classnames';
import { Label } from 'flowbite-react';

import formatMoney from '@/helpers/money';
import ProductSizeModalDelete from './modals/ProductSizeModalDelete';
import ProductSizeModalUpdate from './modals/ProductSizeModalUpdate';

type ProductSizeListItemProps = {
  productSize: Api.ProductSize;
};

function ProductSizeListItem({
  productSize,
}: ProductSizeListItemProps) {
  return (
    <li className="shadow rounded p-2 flex flex-col min-w-[10rem]">
      <div className="p-2 mx-auto relative">
        <div className="flex items-center">
          <div
            className={cx(
              'absolute top-0 left-0 mr-2 h-2.5 w-2.5 rounded-full',
              productSize.status === 'ACTIVE'
                ? 'bg-green-400'
                : 'bg-red-400',
            )}
          />
        </div>
        <img
          src={productSize.imageUrl}
          alt={productSize.name}
          className="w-20 h-20 object-contain mx-auto"
        />
        <small className="absolute top-0 right-0 bg-main px-1 text-white rounded-sm">
          {productSize.order}
        </small>
      </div>
      <div className="text-center flex flex-col grow">
        <Label className="text-sm">
          {productSize.name}
        </Label>
        <span className="text-sm">
          {formatMoney(productSize.basePrice)}
        </span>
        <div className="grow" />
        <div className="flex justify-center items-center gap-2">
          <ProductSizeModalUpdate
            productSize={productSize}
          />
          <ProductSizeModalDelete
            productSizeId={productSize.id}
          />
        </div>
      </div>
    </li>
  );
}

export default ProductSizeListItem;
