import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';

import {
  HiOutlineExclamationCircle,
  HiTrash,
} from 'react-icons/hi';

import { mutateStoreDelete } from '@/api/stores';
import { toastMsg } from '@/components/Toast';

type DeleteStoreModalProps = {
  storeId: number;
};

function DeleteStoreModal({
  storeId,
}: DeleteStoreModalProps) {
  const mutate = mutateStoreDelete();
  const [isOpen, setOpen] = useState(false);

  const handleDeleteStore = async () => {
    await mutate.mutateAsync(storeId).then(() => {
      setOpen(false);
      toastMsg({ title: 'Tienda eliminada' });
    });
  };

  const handleClose = () => {
    if (mutate.isLoading) return;
    setOpen(false);
  };

  return (
    <>
      <Button
        color="failure"
        onClick={() => {
          setOpen(!isOpen);
        }}>
        <HiTrash className="mr-2 text-lg" />
        Eliminar
      </Button>
      <Modal onClose={handleClose} show={isOpen} size="md">
        <Modal.Header className="px-3 pt-3 pb-0">
          <span className="sr-only">Borrar Tienda</span>
        </Modal.Header>
        <Modal.Body className="px-6 pb-6 pt-0">
          <div className="flex flex-col items-center gap-y-6 text-center">
            <HiOutlineExclamationCircle className="text-7xl text-red-600" />
            <p className="text-lg text-gray-500 ">
              ¿Estás seguro de que quieres eliminar esta
              tienda?
            </p>
            <div className="flex items-center gap-x-3">
              <Button
                color="failure"
                onClick={handleDeleteStore}>
                Sí, eliminar
              </Button>
              <Button color="gray" onClick={handleClose}>
                Cancelar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteStoreModal;
