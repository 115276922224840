import {
  Button,
  Card,
  Label,
  Spinner,
  TextInput,
} from 'flowbite-react';
import { Link } from 'react-router-dom';

import { mutateSignIn } from '@/api/authentication';
import { toastErr } from '@/components/Toast';
import useForm from '@/hooks/useForm';
import env from '@/utils/constants';

function LoginPage() {
  const mutate = mutateSignIn();

  const { email, password, handleInputChange } = useForm({
    email:
      env.VITE_APP_VERSION === 'local'
        ? 'me@juanmartinez.dev'
        : '',
    password:
      env.VITE_APP_VERSION === 'local' ? 'root' : '',
  });

  const handleLogin = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    mutate.mutateAsync({ email, password }).catch(() => {
      toastErr({ title: 'Credenciales incorrectas' });
    });
  };
  return (
    <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
      <div className="my-6 flex items-center gap-x-1 lg:my-0">
        <img
          alt="Chaska logo"
          src="/logo.png"
          className="mr-3 h-16"
        />
      </div>
      <Card
        horizontal
        className="w-full md:max-w-[1024px] [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full md:[&>*]:p-16 lg:[&>img]:block">
        <h1 className="mb-3 text-2xl font-bold md:text-3xl">
          Inicio de sesión
        </h1>
        <form onSubmit={handleLogin}>
          <div className="mb-4 flex flex-col gap-y-3">
            <Label htmlFor="email">Tú email</Label>
            <TextInput
              id="email"
              name="email"
              placeholder="name@company.com"
              type="email"
              required
              value={email}
              onChange={(e) => {
                handleInputChange(e.target);
              }}
            />
          </div>
          <div className="mb-6 flex flex-col gap-y-3">
            <Label htmlFor="password">Tú password</Label>
            <TextInput
              id="password"
              name="password"
              placeholder="••••••••"
              type="password"
              required
              value={password}
              onChange={(e) => {
                handleInputChange(e.target);
              }}
            />
          </div>
          <div className="mb-6 flex items-center justify-between">
            <Button
              color="main"
              type="submit"
              className="w-full lg:w-auto"
              disabled={mutate.isLoading}>
              Iniciar sesión
              {mutate.isLoading && (
                <Spinner
                  className="ml-2"
                  size="sm"
                  color="failure"
                />
              )}
            </Button>
            <Link
              to="/password-reset"
              className="w-1/2 text-right text-sm text-main hover:underline">
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default LoginPage;
