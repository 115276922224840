import { Button, Modal } from 'flowbite-react';
import moment from 'moment';
import { useState } from 'react';

import { useProductIngredientsAvailable } from '@/api/ingredients';
import QueryResult from '@/components/QueryResult';
import useProductCtx from '@/hooks/useProductCtx';
import ProductIngredientsAdmin from '../ProductIngredientsAdmin';

function ProductIngredientModalAdmin() {
  const { productId, lastTimeEdited } = useProductCtx();
  const [isOpen, setOpen] = useState(false);
  const productIngredientsAvailable =
    useProductIngredientsAvailable(productId, isOpen);

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        size="xs">
        Administrar Ingredientes
      </Button>
      <Modal
        size="4xl"
        onClose={handleCloseModal}
        show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 ">
          <strong>Administrar Ingredientes</strong>
        </Modal.Header>
        <Modal.Body>
          <QueryResult query={productIngredientsAvailable}>
            <ProductIngredientsAdmin
              productIngredientsAvailable={
                productIngredientsAvailable.data?.results ??
                []
              }
              key={moment(lastTimeEdited).format(
                'YYYY-MM-DDTHH:mm:ss',
              )}
            />
          </QueryResult>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductIngredientModalAdmin;
