import { Flowbite } from 'flowbite-react';
import { BrowserRouter } from 'react-router-dom';

import theme from '@/flowbite-theme';
import AppRoutes from './AppRoutes';

function Router() {
  return (
    <Flowbite theme={{ theme }}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Flowbite>
  );
}

export default Router;
