import { useStoreSummary } from '@/api/stores';
import QueryResult from '@/components/QueryResult';
import formatMoney from '../../helpers/money';
import DaySummaryItems from './DaySummaryItems';

type DaySummaryProps = {
  date: Date;
};

function DaySummary({ date }: DaySummaryProps) {
  const storeSummary = useStoreSummary(1, date.getTime());
  const { data } = storeSummary;

  return (
    <QueryResult query={storeSummary}>
      <>
        <h2>
          Venta del Día:&nbsp;
          {formatMoney(data?.results.totalSales ?? 0)}
        </h2>
        <table>
          <thead>
            <tr>
              <th className="text-left">Producto</th>
              <th className="text-left">Cantidad</th>
              <th className="text-left">Total</th>
            </tr>
          </thead>
          <tbody>
            <DaySummaryItems
              productsSold={
                data?.results.productsSold ?? {}
              }
            />
          </tbody>
        </table>
      </>
    </QueryResult>
  );
}

export default DaySummary;
