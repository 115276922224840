import { Breadcrumb } from 'flowbite-react';
import { useNavigate, useParams } from 'react-router';

import { HiHome } from 'react-icons/hi';

import { mutateStoreUpdate, useStore } from '@/api/stores';
import QueryResult from '@/components/QueryResult';
import { toastMsg } from '@/components/Toast';
import StoreProvider from '@/context/store/StoreProvider';
import AdminStoreList from '@/features/stores/AdminStoreList';
import RefreshTokenCard from '@/features/stores/RefreshTokenCard';
import StoreForm, {
  StoreFormValues,
} from '@/features/stores/StoreForm';
import InventoryCard from '@/features/stores/inventory/InventoryCard';
import AdminModalCreate from '@/features/stores/users/AdminModalCreate';
import NavbarSidebar from '@/layouts/NavbarSidebar';

function StorePageUpdate() {
  const { id } = useParams();
  const mutate = mutateStoreUpdate();
  const navigate = useNavigate();

  const storeId = Number(id);
  const store = useStore(storeId);

  if (!storeId) {
    navigate('/stores');
  }

  const handleUpdateStore = async ({
    name,
    address,
  }: StoreFormValues) => {
    const storeUpdated = {
      id: storeId,
      name,
      address,
    } as Api.Store;
    await mutate.mutateAsync(storeUpdated).then(() => {
      navigate('/stores');
      toastMsg({ title: 'Tienda actualizada' });
    });
  };

  return (
    <NavbarSidebar>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4   sm:flex">
        <div className="w-full">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <div className="flex items-center gap-x-3">
                <HiHome className="text-xl" />
                <span className="">Inicio</span>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/stores">
              Tiendas
            </Breadcrumb.Item>
            <Breadcrumb.Item>Editar Tienda</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto p-4">
          <QueryResult query={store}>
            {store.data && (
              <StoreProvider store={store.data}>
                <div className="grid grid-cols-2 gap-4">
                  <div className="rounded-lg bg-white p-4 shadow">
                    <h1 className="text-xl font-semibold text-gray-900  sm:text-2xl">
                      Detalles de la Tienda
                    </h1>
                    <StoreForm
                      callback={handleUpdateStore}
                      initialState={{
                        name: store.data.name || '',
                        address: store.data.address || '',
                      }}
                      isLoading={mutate.isLoading}
                    />
                  </div>
                  <div className="rounded-lg bg-white p-4 shadow">
                    <div className="flex justify-between">
                      <h1 className="text-xl font-semibold text-gray-900  sm:text-2xl">
                        Administradores
                      </h1>
                      <AdminModalCreate />
                    </div>
                    <AdminStoreList />
                  </div>
                  <div className="rounded-lg bg-white p-4 shadow col-span-2">
                    <h1 className="text-xl font-semibold text-gray-900  sm:text-2xl">
                      Token de Acceso
                    </h1>
                    <RefreshTokenCard store={store.data} />
                  </div>
                  <div className="rounded-lg bg-white p-4 shadow col-span-2">
                    <h1 className="text-xl font-semibold text-gray-900  sm:text-2xl">
                      Inventario
                    </h1>
                    <InventoryCard />
                  </div>
                </div>
              </StoreProvider>
            )}
          </QueryResult>
        </div>
      </div>
    </NavbarSidebar>
  );
}

export default StorePageUpdate;
