function HeaderPrint() {
  return (
    <div className="hidden print:block">
      <h2 className="text-black text-center text-base font-serif">
        Chaska
      </h2>
      <h2 className="text-black text-center text-base font-serif">
        Mexidreams SL
      </h2>
      <h2 className="text-black text-center text-base font-serif">
        Plaza del Cuadro 3
      </h2>
      <h2 className="text-black text-center text-base font-serif">
        Santander.
      </h2>
      <h2 className="text-black text-center text-base font-serif">
        CIF: B39779913 Tel. 633950053
      </h2>
    </div>
  );
}

export default HeaderPrint;
