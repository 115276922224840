import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import ProductIngredientForm from '../ProductIngredientForm';

import { mutateProductIngredient } from '@/api/ingredients';
import {
  toastErrRequest,
  toastMsg,
} from '@/components/Toast';
import useProductCtx from '@/hooks/useProductCtx';

type ProductIngredientModalCreateProps = {
  callback: (productIngredientId: number) => void;
};

function ProductIngredientModalCreate({
  callback,
}: ProductIngredientModalCreateProps) {
  const { productId } = useProductCtx();
  const [isOpen, setOpen] = useState(false);
  const [initialState, setInitialState] = useState<
    Partial<Api.ProductIngredient>
  >({});
  const [formKey, setFormKey] = useState(0);

  const mutate = mutateProductIngredient();

  const handleCloseModal = () => {
    setOpen(false);
    setInitialState({});
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleUpdateProductIngredient = (
    productIngredientUpdated: Omit<
      Api.ProductIngredient,
      'id'
    >,
  ) => {
    mutate
      .mutateAsync({
        id: 90,
        ...productIngredientUpdated,
        productId,
      })
      .then((response) => {
        const res =
          response as unknown as Api.ProductIngredient;
        toastMsg({ title: 'Tamaño creado' });
        handleCloseModal();
        callback(res.id);
      })
      .catch((reason: unknown) => {
        toastErrRequest(reason);
      });
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        size="xs">
        <HiPlus className="text-base mr-2" />
        Agregar ingrediente
      </Button>
      <Modal onClose={handleCloseModal} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 ">
          <strong>Agregar ingrediente</strong>
        </Modal.Header>
        <Modal.Body>
          <ProductIngredientForm
            key={formKey}
            callback={handleUpdateProductIngredient}
            initialState={initialState}
            cancelCallback={handleCloseModal}
            isLoading={mutate.isLoading}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductIngredientModalCreate;
