import { Timeline } from 'flowbite-react';

import { useEffect } from 'react';
import { HiOutlineDeviceMobile } from 'react-icons/hi';

import { useProductSizes } from '@/api/sizes';
import QueryResult from '@/components/QueryResult';
import useProductCtx from '@/hooks/useProductCtx';
import ProductSizeList from './ProductSizeList';
import ProductSizeModalCreate from './modals/ProductSizeModalCreate';

function ProductSizes() {
  const { product, productId, setProductSizes } =
    useProductCtx();
  const productSizes = useProductSizes(productId);

  useEffect(() => {
    if (
      productSizes.data &&
      !productSizes.isLoading &&
      !productSizes.isError
    ) {
      setProductSizes(productSizes.data.results ?? []);
    }
  }, [
    productSizes.data,
    productSizes.isLoading,
    productSizes.isError,
  ]);

  if (!product || product.type !== 'VARIABLE') {
    return null;
  }

  return (
    <Timeline.Item>
      <Timeline.Point icon={HiOutlineDeviceMobile} />
      <Timeline.Content>
        <Timeline.Title className="flex items-center gap-5">
          Tamaño
          <ProductSizeModalCreate />
        </Timeline.Title>
        <Timeline.Body>
          <div className="mt-4">
            <QueryResult query={productSizes}>
              <ProductSizeList
                productSizes={
                  productSizes.data?.results ?? []
                }
              />
            </QueryResult>
          </div>
        </Timeline.Body>
      </Timeline.Content>
    </Timeline.Item>
  );
}

export default ProductSizes;
