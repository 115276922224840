import { Breadcrumb, Label, Table } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { HiHome } from 'react-icons/hi';

import { useOrder } from '@/api/orders';
import Skeleton from '@/components/Skeleton';
import { formatOrderOutput } from '@/helpers/formatOrderOutput';
import formatMoney from '@/helpers/money';
import NavbarSidebar from '@/layouts/NavbarSidebar';
import { paymentMethods } from '@/utils/url';

function OrderDetailedPage() {
  const { id } = useParams();
  const state = useLocation().state as { order: Api.Order };
  const order = useOrder(id as never);

  const [orderToDisplay, setOrderToDisplay] =
    useState<Api.Order | null>(null);

  useEffect(() => {
    if (state.order) {
      setOrderToDisplay(state.order);
    }
  }, []);

  useEffect(() => {
    if (order.data) {
      setOrderToDisplay(order.data.results);
    }
  }, [order.data]);

  if (!orderToDisplay) {
    return <Skeleton />;
  }

  return (
    <NavbarSidebar>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4   sm:flex">
        <div className="w-full">
          <div className="flex justify-between items-center">
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="">Inicio</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/orders">Pedidos</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                Pedido #
                {orderToDisplay?.id && orderToDisplay.id}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="block items-center sm:flex">
            <div className="flex w-full items-center sm:justify-end" />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        {!orderToDisplay && <Skeleton />}
        {orderToDisplay && (
          <div className="col-span-12 mx-4 mb-4 rounded-lg bg-white p-4 shadow  sm:p-6 md:mx-6 lg:my-6 xl:col-span-10 xl:col-start-2 xl:p-8 2xl:col-span-8 2xl:col-start-3">
            <div className="space-y-6 overflow-hidden p-4 md:p-8">
              <div className="sm:flex">
                <div className="mb-5 text-2xl font-bold  sm:mb-0 sm:text-3xl">
                  {`Pedido #${orderToDisplay.dailyOrderNumber}`}
                </div>
              </div>
              <div className="sm:w-72">
                <div className="mb-4 text-base font-bold uppercase text-gray-900 ">
                  Cliente:&nbsp;
                  <strong>{`${orderToDisplay.user.firstName} ${orderToDisplay.user.lastName}`}</strong>
                </div>
                <address className="text-base font-normal text-gray-500 ">
                  Método de Pago:&nbsp;
                  <strong>
                    {
                      paymentMethods[
                        orderToDisplay.paymentMethod
                      ]
                    }
                  </strong>
                </address>
              </div>
              <div className="my-8 flex flex-col">
                <div className="overflow-x-auto border-b border-gray-200 ">
                  <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden shadow">
                      <Table className="min-w-full text-gray-900">
                        <Table.Head>
                          <Table.HeadCell>
                            Producto
                          </Table.HeadCell>
                          <Table.HeadCell>
                            Cantidad
                          </Table.HeadCell>
                          <Table.HeadCell>
                            Detalles
                          </Table.HeadCell>
                          <Table.HeadCell>
                            Total
                          </Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                          {orderToDisplay.orderItems.map(
                            (orderItem, i) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Table.Row
                                key={`${orderToDisplay.id}_${orderItem.product.id}_${i}`}>
                                <Table.Cell className="whitespace-nowrap p-4 text-sm font-normal">
                                  <img
                                    src={
                                      orderItem.product
                                        .imageUrl
                                    }
                                    alt={
                                      orderItem.product.name
                                    }
                                    className="w-20 h-20 object-contain m-auto"
                                  />
                                  <Label className="w-full block text-center mt-1">
                                    {orderItem.product.name}
                                    {orderItem.productSize &&
                                      ` ${orderItem.productSize.name}`}
                                    {orderItem.product
                                      .basePrice &&
                                      ` - ${formatMoney(
                                        orderItem.product
                                          .basePrice,
                                      )}`}
                                    {!orderItem.product
                                      .basePrice &&
                                      orderItem.productSize
                                        .basePrice &&
                                      ` - ${formatMoney(
                                        orderItem
                                          .productSize
                                          .basePrice,
                                      )}`}
                                  </Label>
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap p-4 text-base font-semibold text-gray-900 ">
                                  {orderItem.quantity}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap p-4 text-base font-semibold">
                                  {formatOrderOutput(
                                    orderItem.productSteps ??
                                      [],
                                  ).map((step) => (
                                    <div
                                      key={`${orderToDisplay.id}-${orderItem.id}-${JSON.stringify(step)}`}>
                                      <strong>
                                        {step.step}
                                      </strong>
                                      <br />
                                      {step.rawMaterial}
                                    </div>
                                  ))}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap p-4 text-base font-semibold">
                                  {orderItem.product
                                    .basePrice &&
                                    formatMoney(
                                      orderItem.product
                                        .basePrice *
                                        orderItem.quantity,
                                    )}
                                  {!orderItem.product
                                    .basePrice &&
                                    orderItem.productSize
                                      .basePrice &&
                                    formatMoney(
                                      orderItem.productSize
                                        .basePrice *
                                        orderItem.quantity,
                                    )}
                                </Table.Cell>
                              </Table.Row>
                            ),
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-3 sm:ml-auto sm:w-72 sm:text-right">
                <div className="flex justify-between">
                  <div className="text-base font-semibold uppercase text-gray-900 ">
                    Total
                  </div>
                  <div className="text-base font-bold text-gray-900 ">
                    {formatMoney(orderToDisplay.total)}
                  </div>
                </div>
                {orderToDisplay?.orderTransactions?.map(
                  (transaction) => (
                    <>
                      <div className="flex justify-between">
                        <div className="text-base font-medium uppercase text-gray-600">
                          Pago:
                        </div>
                        <div className="text-base font-bold text-gray-900 ">
                          {formatMoney(transaction.amount)}
                        </div>
                      </div>
                    </>
                  ),
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </NavbarSidebar>
  );
}

export default OrderDetailedPage;
