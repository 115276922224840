import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { HiPencilAlt } from 'react-icons/hi';
import ProductSizeForm from '../ProductSizeForm';

import { mutateProductSizeUpdate } from '@/api/sizes';
import {
  toastErrRequest,
  toastMsg,
} from '@/components/Toast';
import { formatCentsToEuro } from '@/helpers/money';

type ProductSizeModalUpdateProps = {
  productSize: Api.ProductSize;
};

function ProductSizeModalUpdate({
  productSize,
}: ProductSizeModalUpdateProps) {
  const [isOpen, setOpen] = useState(false);
  const mutate = mutateProductSizeUpdate();

  const handleUpdateProductSize = (
    productSizeUpdated: Omit<Api.ProductSize, 'id'>,
  ) => {
    mutate
      .mutateAsync({
        id: productSize.id,
        ...productSizeUpdated,
      })
      .then(() => {
        setOpen(false);
        toastMsg({ title: 'Tamaño actualizado' });
      })
      .catch((reason: unknown) => {
        toastErrRequest(reason);
      });
  };

  return (
    <>
      <button
        type="button"
        className="flex items-center justify-center text-sm text-blue-700 mt-2 hover:text-blue-500"
        onClick={() => {
          setOpen(!isOpen);
        }}>
        <HiPencilAlt className="mr-1 text-lg" />
        Editar
      </button>
      <Modal
        onClose={() => {
          setOpen(false);
        }}
        show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 ">
          <strong>Editar tamaño</strong>
        </Modal.Header>
        <Modal.Body>
          <ProductSizeForm
            callback={handleUpdateProductSize}
            initialState={{
              ...productSize,
              basePrice: formatCentsToEuro(
                productSize.basePrice,
              ),
            }}
            isLoading={mutate.isLoading}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductSizeModalUpdate;
