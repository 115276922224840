/* eslint-disable @typescript-eslint/no-explicit-any */
export const types = {
  login: '[Auth] Login',
  logout: '[Auth] Logout',
};

export const initialState: {
  isLoggedIn: 'idle' | boolean;
  user: Api.User | null;
} = {
  isLoggedIn: 'idle',
  user: null,
};

export const authReducer = (
  state: typeof initialState,
  action: { type: string; payload?: any },
) => {
  switch (action.type) {
    case types.login:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        user: action.payload as Api.User,
        isLoggedIn: true,
      };
    case types.logout:
      return {
        user: null,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
