import { createContext } from 'react';

export type AuthContextInterface = {
  readonly isLoggedIn: 'idle' | boolean;
  user: Api.User | null;
  handleLogin: (login: Api.User) => void;
  handleLogout: () => void;
};

const authContextInitialState: AuthContextInterface = {
  isLoggedIn: 'idle',
  user: null,
  handleLogin: () => null,
  handleLogout: () => null,
};

export const AuthContext =
  createContext<AuthContextInterface>(
    authContextInitialState,
  );
