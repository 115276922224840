type GroupedResult = {
  step: string;
  rawMaterial: {
    name: string;
    count: number;
  }[];
};

const groupedResults = (
  productSteps: Api.ProductStep[],
): GroupedResult[] =>
  productSteps.reduce(
    (
      acc: {
        step: string;
        rawMaterial: { name: string; count: number }[];
      }[],
      { step, rawMaterial }: Api.ProductStep,
    ) => {
      const existingStep = acc.find(
        (item) => item.step === step,
      );
      if (existingStep) {
        const existingRawMaterial =
          existingStep.rawMaterial.find(
            (material) => material.name === rawMaterial,
          );
        if (existingRawMaterial) {
          existingRawMaterial.count++;
        } else {
          existingStep.rawMaterial.push({
            name: rawMaterial,
            count: 1,
          });
        }
      } else {
        acc.push({
          step,
          rawMaterial: [{ name: rawMaterial, count: 1 }],
        });
      }
      return acc;
    },
    [],
  );

export const formatOrderOutput = (
  productSteps: Api.ProductStep[],
) => {
  const gResults = groupedResults(productSteps);
  return gResults.map(({ step, rawMaterial }) => ({
    step,
    rawMaterial: rawMaterial
      .map(({ name, count }) => `${name} x ${count} \n`)
      .join(' '),
  }));
};
