import { useQuery } from 'react-query';
import { axiosMedia as axios } from './axios';

import makeMutation from '@/hooks/makeMutation';

const PATH = '/wp-json/ck/v1/media';

const getMedia = (): Promise<Api.Response<Api.Media[]>> =>
  axios.get(`${PATH}/all`);
const useMedia = (enabled = true) =>
  useQuery('media', () => getMedia(), { enabled });

const postMedia = (
  media: Partial<Api.Media>,
): Promise<Api.Response<Api.Media>> => {
  const formData = new FormData();
  formData.append('file', media as File);
  return axios.post(`${PATH}/create`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const mutateMedia = makeMutation(['/media'], postMedia, [
  'media',
]);

export { mutateMedia, useMedia };
