import {
  Button,
  Label,
  TextInput,
  ToggleSwitch,
} from 'flowbite-react';
import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import Spinner from '@/components/Spinner';
import { formatMoneyToNumber } from '@/helpers/money';
import useForm from '@/hooks/useForm';
import MediaModal from '../media/MediaModal';

type ProductFormProps = {
  callback: (values: Partial<Api.Product>) => void;
  initialState: Partial<Api.Product>;
  isLoading: boolean;
};

function ProductForm({
  callback,
  initialState,
  isLoading,
}: ProductFormProps) {
  const [media, setMedia] = useState<Api.Media | null>(
    initialState.imageUrl
      ? ({
          id: 0,
          name: '',
          image: initialState.imageUrl,
        } as Api.Media)
      : null,
  );

  const { name, basePrice, order, handleInputChange } =
    useForm(initialState);
  const [isProductVariable, setIsProductVariable] =
    useState(initialState.type === 'VARIABLE');
  const [isProductActive, setIsProductActive] = useState(
    initialState.status === 'ACTIVE',
  );

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    if (!media) return;
    const type = (
      isProductVariable ? 'VARIABLE' : 'SIMPLE'
    ) as Api.ProductType;
    const status = (
      isProductActive ? 'ACTIVE' : 'INACTIVE'
    ) as Api.Status;
    const price = isProductVariable
      ? undefined
      : formatMoneyToNumber(basePrice ?? 0);
    callback({
      name,
      basePrice: price,
      imageUrl: media.image,
      order,
      type,
      status,
    });
  };

  const handleCurrencyChange = (
    value: string | undefined,
    n?: string | undefined,
  ) => {
    handleInputChange({ name: n ?? 'basePrice', value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-6">
        <div>
          <Label htmlFor="name">Nombre</Label>
          <TextInput
            id="name"
            name="name"
            placeholder="Santander"
            className="mt-1"
            value={name}
            onChange={(e) => {
              handleInputChange(e.target);
            }}
          />
        </div>
        <MediaModal
          onSuccess={setMedia}
          initialState={media}
          key={media?.id}
          title={
            media?.image
              ? 'Cambiar Imagen'
              : 'Establecer imagen'
          }
        />
        <ToggleSwitch
          checked={isProductVariable}
          label="Producto Variable"
          onChange={setIsProductVariable}
        />
        {!isProductVariable && (
          <div>
            <Label htmlFor="basePrice">Precio</Label>
            <CurrencyInput
              id="basePrice"
              name="basePrice"
              placeholder="Precio"
              // eslint-disable-next-line max-len
              className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500       rounded-lg p-2.5 text-sm"
              value={basePrice}
              onValueChange={handleCurrencyChange}
              decimalsLimit={2}
              decimalScale={2}
              decimalSeparator=","
              groupSeparator=" "
              prefix="€"
              required={!isProductVariable}
            />
          </div>
        )}
        <div>
          <Label htmlFor="order">Orden</Label>
          <TextInput
            id="order"
            name="order"
            placeholder="Orden en que es mostrado en la app"
            className="mt-1"
            value={order}
            onChange={(e) => {
              handleInputChange(e.target);
            }}
            type="number"
            min={0}
          />
        </div>
        <ToggleSwitch
          checked={isProductActive}
          label="Publicado"
          onChange={setIsProductActive}
        />
        <div className="mb-6 flex items-center justify-between">
          <Button
            color="main"
            type="submit"
            className="w-full lg:w-auto"
            disabled={isLoading}>
            Guardar
            {isLoading && (
              <Spinner size="sm" className="ml-2" />
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default ProductForm;
