import { useQuery } from 'react-query';
import { axios } from './axios';

import makeMutation from '@/hooks/makeMutation';

const getStores = (): Promise<Api.Response<Api.Store[]>> =>
  axios.get('/admin/stores');
const getStore = (id: number): Promise<Api.Store> =>
  axios.get(`/admin/stores/${id}`);
const postStore = (store: Omit<Api.Store, 'id'>) =>
  axios.post('/admin/stores', store);
const putStore = (store: Api.Store) =>
  axios.put(`/admin/stores/${store.id}`, store);
const deleteStore = (id: number) =>
  axios.delete(`/admin/stores/${id}`);
const getStoreSales = (
  id: number,
  startDate: number,
  endDate: number,
): Promise<Api.Response<Api.SalesReport[]>> =>
  axios.get(`/admin/stores/${id}/sales`, {
    params: { startDate, endDate },
  });
const getStoreSummary = (
  id: number,
  date: number,
): Promise<Api.Response<Api.SalesSummary>> =>
  axios.get(`/admin/stores/${id}/summary`, {
    params: { date },
  });
const postRefreshToken = (id: number) =>
  axios.post(`/admin/stores/${id}/token`);

const useStores = () => useQuery('stores', getStores);
const useStore = (id: number) =>
  useQuery(['stores', id], () => getStore(id));
const useStoreSales = (
  id: number,
  startDate: number,
  endDate: number,
) =>
  useQuery(['stores', id, startDate, endDate], () =>
    getStoreSales(id, startDate, endDate),
  );
const useStoreSummary = (id: number, date: number) =>
  useQuery(['stores', id, date], () =>
    getStoreSummary(id, date),
  );
const mutateStore = makeMutation(['/store'], postStore, [
  'stores',
]);
const mutateStoreUpdate = makeMutation(
  ['/store'],
  putStore,
  ['stores'],
);
const mutateRefreshToken = makeMutation(
  ['/store'],
  postRefreshToken,
  ['stores'],
);

const mutateStoreDelete = makeMutation(
  ['/store'],
  deleteStore,
  ['stores'],
);

export {
  mutateRefreshToken,
  mutateStore,
  mutateStoreDelete,
  mutateStoreUpdate,
  useStore,
  useStoreSales,
  useStoreSummary,
  useStores,
};
