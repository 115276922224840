export default function formatMoney(
  amount: number,
): string {
  // the money amount is in cents, so we need to divide by 100
  const amountToFormat: number = amount / 100;
  const formattedAmount: string =
    amountToFormat.toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      minimumIntegerDigits: 1,
      useGrouping: true,
    });
  return formattedAmount;
}

export function formatCentsToEuro(
  amount: number | undefined,
): number | undefined {
  if (!amount) {
    return undefined;
  }
  return amount / 100;
}

export function formatMoneyToNumber(
  amount: number,
): number {
  // if the amount has not comma (,) then it means that the value is not in cents
  if (!`${amount}`.includes(',')) {
    // eslint-disable-next-line no-param-reassign
    amount *= 100;
  }
  const amountToFormat: string = `${amount}`
    .replaceAll(' ', '')
    .replaceAll(',', '');
  const formattedAmount = Number(amountToFormat);
  return formattedAmount;
}
