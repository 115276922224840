import Axios, { AxiosRequestConfig } from 'axios';

import env from '@/utils/constants';

function authRequestInterceptor(
  config: AxiosRequestConfig,
) {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: env.VITE_API_URL,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

axios.interceptors.request.use(
  authRequestInterceptor as never,
);
axios.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  (response) => response.data,
  (error) => {
    console.error(error);
    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
    return Promise.reject(error);
  },
);

export const axiosMedia = Axios.create({
  baseURL: env.VITE_MEDIA_API_URL,
  withCredentials: true,
  params: {
    token: '123456',
  },
});

axiosMedia.interceptors.request.use(
  authRequestInterceptor as never,
);
axiosMedia.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  (response) => response.data,
  (error) => {
    console.error(error);
    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
    return Promise.reject(error);
  },
);

export default axios;
