import { Breadcrumb, Button } from 'flowbite-react';
import { Link } from 'react-router-dom';

import { FaPlus } from 'react-icons/fa';
import { HiHome } from 'react-icons/hi';

import { useStores } from '@/api/stores';
import QueryResult from '@/components/QueryResult';
import StoresTable from '@/features/stores/StoresTable';
import NavbarSidebar from '@/layouts/NavbarSidebar';

function StoresPage() {
  const storesQuery = useStores();
  return (
    <NavbarSidebar>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4   sm:flex">
        <div className="w-full">
          <div className="flex justify-between items-center">
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="">Inicio</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Tiendas</Breadcrumb.Item>
            </Breadcrumb>
            <Link to="/stores/create">
              <Button color="primary" className="text-sm">
                <FaPlus className="mr-3 text-sm" />
                Agregar Tienda
              </Button>
            </Link>
          </div>
          <div className="block items-center sm:flex">
            <div className="flex w-full items-center sm:justify-end" />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <QueryResult query={storesQuery}>
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow">
                <StoresTable
                  stores={storesQuery?.data?.results ?? []}
                />
              </div>
            </div>
          </div>
        </QueryResult>
      </div>
    </NavbarSidebar>
  );
}

export default StoresPage;
