import { Table } from 'flowbite-react';

import useProductCtx from '@/hooks/useProductCtx';
import ProductIngredientLimitModalUpdate from './modals/ProductIngredientLimitModalUpdate';

type ProductIngredientLimitTableProps = {
  productIngredient: Api.ProductIngredient;
};

function ProductIngredientLimitTable({
  productIngredient,
}: ProductIngredientLimitTableProps) {
  const { productSizes } = useProductCtx();

  if (!productSizes) return null;

  const productSizeList = productSizes.map((size) => ({
    ...size,
    maxOptions: productIngredient.maxOptions.find(
      (s) => s.productSize.id === size.id,
    ),
  }));

  if (!productIngredient.allowMultipleSelection) {
    return null;
  }

  if (productSizeList.length === 0) return null;

  return (
    <div>
      <Table hoverable>
        <Table.Head>
          <Table.HeadCell className="text-xs">
            Tamaño
          </Table.HeadCell>
          <Table.HeadCell className="text-xs" colSpan={2}>
            Porciones
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {productSizeList.map((size) => (
            <Table.Row key={`${size.id}-${size.name}`}>
              <Table.Cell className="text-xs">
                {size.name}
              </Table.Cell>
              <Table.Cell className="text-xs">
                {productIngredient.maxOptions.find(
                  (s) => s.productSize.id === size.id,
                )?.maxOptions ?? '-'}
              </Table.Cell>
              <Table.Cell className="text-xs">
                <ProductIngredientLimitModalUpdate
                  productSizeMaxOptionsId={
                    size.maxOptions?.id
                  }
                  productSize={size}
                  productIngredient={productIngredient}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default ProductIngredientLimitTable;
