import Router from '@/router/Router';
import AppProvider from './context/AppProvider';

function App() {
  return (
    <AppProvider>
      <Router />
    </AppProvider>
  );
}

export default App;
