import { Label } from 'flowbite-react';
import ProductRawMaterialModalCreate from './modals/ProductRawMaterialModalCreate';
import ProductRawMaterialModalDelete from './modals/ProductRawMaterialModalDelete';
import ProductRawMaterialModalUpdate from './modals/ProductRawMaterialModalUpdate';

type ProductRawMaterialsListCardProps = {
  title: string;
  productRawMaterials: Api.ProductRawMaterial[];
  displayOrder?: boolean;
  displayCreateButton?: boolean;
  ingredientId?: number;
};

function ProductRawMaterialsListCard({
  title,
  productRawMaterials,
  displayOrder = false,
  displayCreateButton = false,
  ingredientId,
}: ProductRawMaterialsListCardProps) {
  if (!productRawMaterials) {
    return null;
  }

  return (
    <div className="shadow rounded p-4 flex flex-col min-w-[18rem]">
      <div className=" flex flex-col grow">
        <div className="mb-2 text-center">
          <Label className="text-sm">{title}</Label>
        </div>
        <ul className="grid grid-cols-3 text-center gap-y-4">
          {productRawMaterials.map((productRawMaterial) => (
            <li
              key={productRawMaterial.id}
              className="flex flex-col relative">
              <img
                src={productRawMaterial.imageUrl}
                alt={productRawMaterial.name}
                className="w-12 h-12 object-contain mx-auto"
              />
              <p className="text-xs leading-3 flex justify-center items-center gap-1 grow">
                {productRawMaterial.name}
              </p>
              <div className="flex justify-center items-center gap-2">
                {displayCreateButton && (
                  <>
                    <ProductRawMaterialModalUpdate
                      productRawMaterial={
                        productRawMaterial
                      }
                    />
                    <ProductRawMaterialModalDelete
                      productRawMaterial={
                        productRawMaterial
                      }
                    />
                  </>
                )}
              </div>
              {displayOrder && (
                <small className="top-0 right-0 absolute ml-1 bg-main px-1 text-white rounded-sm">
                  {productRawMaterial.order}
                </small>
              )}
            </li>
          ))}
        </ul>
        {displayCreateButton && ingredientId && (
          <ProductRawMaterialModalCreate
            ingredientId={ingredientId}
          />
        )}
      </div>
    </div>
  );
}

export default ProductRawMaterialsListCard;
