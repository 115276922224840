const validatePaymentMethod = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'CASH':
      return 'CashKeeper';
    case 'CARD':
      return 'Pago con Tarjeta';
    case 'CASH_ON_DELIVERY':
    default:
      return 'Pago en Efectivo';
  }
};

export default validatePaymentMethod;
