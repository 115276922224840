import { useRoutes } from 'react-router-dom';

import useAuth from '@/hooks/useAuth';
import protectedRoutes from './ProtectedRoutes';
import publicRoutes from './PublicRoutes';

function AppRoutes() {
  const { isLoggedIn } = useAuth();

  const routes = [];

  if (isLoggedIn) {
    routes.push(...protectedRoutes);
  }

  if (!isLoggedIn) {
    routes.push(...publicRoutes);
  }

  const element = useRoutes([...routes]);

  return element;
}

export default AppRoutes;
