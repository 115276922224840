import cx from 'classnames';
import { Button, Table } from 'flowbite-react';
import { useNavigate } from 'react-router';

import {
  HiOutlinePhotograph,
  HiPencilAlt,
} from 'react-icons/hi';

// import DeleteProductModal from './DeleteProductModal';
import ProductTablePrice from './ProductTablePrice';

function ProductsTable({
  products,
}: {
  products: Api.Product[];
}) {
  const navigate = useNavigate();

  return (
    <Table className="min-w-full divide-y divide-gray-200 ">
      <Table.Head className="bg-gray-100 ">
        <Table.HeadCell>
          <HiOutlinePhotograph className="mr-2 text-lg" />
        </Table.HeadCell>
        <Table.HeadCell>Producto</Table.HeadCell>
        <Table.HeadCell>Precio</Table.HeadCell>
        <Table.HeadCell>Estatus</Table.HeadCell>
        <Table.HeadCell>Orden</Table.HeadCell>
        <Table.HeadCell />
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white  ">
        {products.length === 0 && (
          <Table.Row className="hover:bg-gray-100 ">
            <Table.Cell
              colSpan={5}
              className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              No hay Productos
            </Table.Cell>
          </Table.Row>
        )}
        {products.map((product) => (
          <Table.Row
            className="hover:bg-gray-100  cursor-pointer"
            key={product.id}
            onClick={() => {
              navigate(`/products/edit/${product.id}`);
            }}>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              <img
                className="w-10 h-10"
                src={product.imageUrl}
                alt={`Product - ${product.name}`}
              />
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {product.name}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              <ProductTablePrice product={product} />
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-normal text-gray-900 ">
              <div className="flex items-center">
                <div
                  className={cx(
                    'mr-2 h-2.5 w-2.5 rounded-full',
                    product.status === 'ACTIVE'
                      ? 'bg-green-400'
                      : 'bg-red-400',
                  )}
                />
                {product.status === 'ACTIVE'
                  ? 'Activo'
                  : 'Inactivo'}
              </div>
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 ">
              {product.order}
            </Table.Cell>
            <Table.Cell className="space-x-2 whitespace-nowrap p-4">
              <div className="flex items-center gap-x-3 justify-end">
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    navigate(
                      `/products/edit/${product.id}`,
                    );
                  }}>
                  <HiPencilAlt className="mr-2 text-lg" />
                  Editar
                </Button>
                {/* <DeleteProductModal productId={product.id} /> */}
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default ProductsTable;
