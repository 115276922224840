import { Navbar } from 'flowbite-react';

import OrdersOnHold from '@/features/orders/OrdersOnHold';
import OrdersOnProcessing from '@/features/orders/OrdersOnProcessing';
import FooterPrint from '@/features/print/FooterPrint';
import HeaderPrint from '@/features/print/HeaderPrint';

function OnHoldPage() {
  return (
    <>
      <HeaderPrint />
      <Navbar fluid className="shadow-md print:hidden">
        <div className=" grid place-items-center w-full p-3 lg:px-5 lg:pl-3">
          <img
            src="/logo.png"
            alt="logo"
            className="h-10"
          />
        </div>
      </Navbar>
      <div className="w-full my-5">
        <div className="max-w-7xl lg:max-w-[80%] px-6 m-auto gap-5 grid md:grid-cols-2 print:grid-cols-1 my-20">
          <OrdersOnHold />
          <OrdersOnProcessing />
        </div>
      </div>
      <FooterPrint />
    </>
  );
}

export default OnHoldPage;
