import {
  Button,
  Label,
  TextInput,
  ToggleSwitch,
} from 'flowbite-react';
import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import Spinner from '@/components/Spinner';
import { formatMoneyToNumber } from '@/helpers/money';
import useForm from '@/hooks/useForm';
import MediaModal from '../media/MediaModal';

type ProductSizeFormProps = {
  callback: (values: Omit<Api.ProductSize, 'id'>) => void;
  initialState: Partial<Api.ProductSize>;
  isLoading: boolean;
};

function ProductSizeForm({
  callback,
  initialState,
  isLoading,
}: ProductSizeFormProps) {
  const [media, setMedia] = useState<Api.Media | null>(
    initialState.imageUrl
      ? ({
          id: 0,
          name: '',
          image: initialState.imageUrl,
        } as Api.Media)
      : null,
  );

  const { name, basePrice, order, handleInputChange } =
    useForm(initialState);
  const [isProductActive, setIsProductActive] = useState(
    initialState.status === 'ACTIVE',
  );
  const [formErrors, setFormErrors] = useState<
    Record<string, string>
  >({});

  const handleValidation = () => {
    const errors: Record<string, string> = {};
    if (!name) errors.name = 'El nombre es obligatorio';
    if (!basePrice)
      errors.basePrice = 'El precio es obligatorio';
    if (!media?.image)
      errors.imageUrl = 'La imagen es obligatoria';
    if (!order) errors.order = 'El orden es obligatorio';
    setFormErrors(errors);
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    if (!name || !basePrice || !media?.image || !order) {
      handleValidation();
      return;
    }
    const status = (
      isProductActive ? 'ACTIVE' : 'INACTIVE'
    ) as Api.Status;
    const price = formatMoneyToNumber(basePrice);
    callback({
      name,
      basePrice: price,
      imageUrl: media.image,
      order,
      status,
    });
  };

  const handleCurrencyChange = (
    value: string | undefined,
    n?: string | undefined,
  ) => {
    handleInputChange({ name: n ?? 'basePrice', value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-6">
        <div>
          <Label
            htmlFor="productSize-name"
            color={formErrors.name ? 'failure' : ''}>
            Nombre
          </Label>
          <TextInput
            id="productSize-name"
            name="name"
            placeholder="Grande"
            className="mt-1"
            value={name}
            onChange={(e) => {
              handleInputChange(e.target);
            }}
            color={formErrors.name ? 'failure' : ''}
            helperText={
              formErrors.name ? (
                <small className="text-red-500">
                  {formErrors.name}
                </small>
              ) : (
                ''
              )
            }
            required
          />
        </div>
        <MediaModal
          onSuccess={setMedia}
          initialState={media}
          key={media?.id}
          title={
            media?.image
              ? 'Cambiar Imagen'
              : 'Establecer imagen'
          }
        />
        <div>
          <Label
            htmlFor="productSize-basePrice"
            color={formErrors.basePrice ? 'failure' : ''}>
            Precio
          </Label>
          <CurrencyInput
            id="productSize-basePrice"
            name="basePrice"
            placeholder="Precio"
            // eslint-disable-next-line max-len
            className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500       rounded-lg p-2.5 text-sm"
            value={basePrice}
            onValueChange={handleCurrencyChange}
            decimalsLimit={2}
            decimalScale={2}
            decimalSeparator=","
            groupSeparator=" "
            prefix="€"
            required
          />
        </div>
        <div>
          <Label
            htmlFor="productSize-order"
            color={formErrors.order ? 'failure' : ''}>
            Orden
          </Label>
          <TextInput
            id="productSize-order"
            name="order"
            placeholder="Orden en que es mostrado en la app"
            className="mt-1"
            value={order}
            onChange={(e) => {
              handleInputChange(e.target);
            }}
            type="number"
            min={0}
            color={formErrors.order ? 'failure' : ''}
            helperText={
              formErrors.order ? (
                <small className="text-red-500">
                  {formErrors.order}
                </small>
              ) : (
                ''
              )
            }
            required
          />
        </div>
        <ToggleSwitch
          className="hidden"
          checked={isProductActive}
          label="Publicado"
          onChange={setIsProductActive}
        />
        <hr className="my-4" />
        <div className="mb-6 flex items-center justify-between">
          <Button
            color="main"
            type="submit"
            className="w-full lg:w-auto"
            disabled={isLoading}>
            Guardar
            {isLoading && (
              <Spinner size="sm" className="ml-2" />
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default ProductSizeForm;
