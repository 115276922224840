import { Button } from 'flowbite-react';
import { useState } from 'react';
import toast from 'react-hot-toast';

import {
  HiOutlineClipboardCopy,
  HiRefresh,
} from 'react-icons/hi';

import { mutateRefreshToken } from '@/api/stores';
import Loader from '@/components/Loader';

type RefreshTokenCardProps = {
  store: Api.Store;
};
function RefreshTokenCard({
  store,
}: RefreshTokenCardProps) {
  const [refreshToken, setRefreshToken] = useState(
    store.token ?? '',
  );
  const mutate = mutateRefreshToken();

  const handleCopyToken = async () => {
    await navigator.clipboard.writeText(store.token);
    toast.success('Token copiado');
  };

  const handleRefreshToken = async () => {
    await mutate.mutateAsync(store.id).then((res) => {
      // Updated type of the parameter
      const responseToken =
        res as unknown as Api.Response<Api.Store>; // Updated type of the response
      toast.success('Token actualizado');
      setRefreshToken(responseToken.results.token);
    });
  };

  return (
    <div>
      <p className="text-sm text-gray-500 mb-4">
        Este token es utilizado para ligar los totems en la
        tienda con la aplicación.
      </p>
      <div className="flex items-center gap-x-4">
        <div className="flex-1">
          <div className="bg-gray-100 p-2 rounded-md">
            <p className="text-sm font-semibold">Token</p>
            <p className="text-sm text-gray-500 break-all">
              {refreshToken}
            </p>
          </div>
        </div>
        <Button
          color="gray"
          onClick={handleCopyToken}
          disabled={mutate.isLoading}>
          Copiar
          <HiOutlineClipboardCopy className="w-4 h-4 ml-2" />
        </Button>
        <Button
          color="warning"
          disabled={mutate.isLoading}
          onClick={handleRefreshToken}>
          Refrescar
          {mutate.isLoading ? (
            <Loader />
          ) : (
            <HiRefresh className="w-4 h-4 ml-2" />
          )}
        </Button>
      </div>
    </div>
  );
}

export default RefreshTokenCard;
