import {
  Button,
  Label,
  Spinner,
  TextInput,
} from 'flowbite-react';

import useForm from '@/hooks/useForm';

export type StoreFormValues = {
  name: string;
  address: string;
};

type StoreFormProps = {
  callback: (values: StoreFormValues) => void;
  initialState: StoreFormValues;
  isLoading: boolean;
};

function StoreForm({
  callback,
  initialState,
  isLoading,
}: StoreFormProps) {
  const { name, address, handleInputChange } =
    useForm(initialState);

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    callback({ name, address });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-6">
        <div>
          <Label htmlFor="name">Nombre</Label>
          <TextInput
            id="name"
            name="name"
            placeholder="Santander"
            className="mt-1"
            value={name}
            onChange={(e) => {
              handleInputChange(e.target);
            }}
          />
        </div>
        <div>
          <Label htmlFor="address">Direcci&oacute;n</Label>
          <TextInput
            id="address"
            name="address"
            placeholder="Plaza de Ruben Dario 14, 39005 Santander España"
            className="mt-1"
            value={address}
            onChange={(e) => {
              handleInputChange(e.target);
            }}
          />
        </div>
        <div className="mb-6 flex items-center justify-between">
          <Button
            color="main"
            type="submit"
            className="w-full lg:w-auto"
            disabled={isLoading}>
            Guardar
            {isLoading && (
              <Spinner size="sm" className="ml-2" />
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default StoreForm;
