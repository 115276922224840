import { useEffect, useReducer } from 'react';

import {
  ProductContext,
  ProductContextInterface,
} from './ProductContext';
import {
  initialState,
  productReducer,
  types,
} from './ProductReducer';

type ProductProviderProps = {
  children: React.ReactNode;
  product: Api.Product;
};

function ProductProvider({
  children,
  product,
}: ProductProviderProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useReducer(
    productReducer,
    initialState,
  );

  useEffect(() => {
    dispatch({ type: types.setProduct, payload: product });
  }, [product]);

  const setProductIngredientDisplayed = (
    productIngredient: Api.ProductIngredientsAvailable,
  ) => {
    dispatch({
      type: types.setProductIngredientDisplayed,
      payload: productIngredient,
    });
  };

  const setProductSizes = (
    productSizes: Api.ProductSize[],
  ) => {
    dispatch({
      type: types.setProductSizes,
      payload: productSizes,
    });
  };

  const setProductIngredients = (
    productIngredients: Api.ProductIngredient[],
  ) => {
    dispatch({
      type: types.setProductIngredients,
      payload: productIngredients,
    });
  };

  const setLastTimeEdited = (date: Date) => {
    dispatch({
      type: types.setLastTimeEdited,
      payload: date,
    });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: ProductContextInterface = {
    ...state,
    product,
    productId: product.id,
    setProductIngredientDisplayed,
    productSizes: state.productSizes,
    setProductSizes,
    productIngredients: state.productIngredients,
    setProductIngredients,
    lastTimeEdited: state.lastTimeEdited,
    setLastTimeEdited,
  };

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  );
}

export default ProductProvider;
