function FooterPrint() {
  return (
    <div className="hidden print:block">
      <h2 className="text-black text-center text-base font-serif">
        Gracias por su visita
      </h2>
      <h2 className="text-black text-center text-base font-serif">
        IVA INCLUIDO
      </h2>
    </div>
  );
}

export default FooterPrint;
