import { useInventory } from '@/api/inventory';
import QueryResult from '@/components/QueryResult';
import useStoreCtx from '@/hooks/useStoreCtx';
import InventoryItem from './InventoryItem';

function InventoryCard() {
  const { storeId } = useStoreCtx();
  const inventory = useInventory(storeId);

  return (
    <ul className="grid md:grid-cols-2">
      <QueryResult query={inventory}>
        {inventory.data?.results.map((item) => (
          <InventoryItem key={item.id} item={item} />
        ))}
      </QueryResult>
    </ul>
  );
}

export default InventoryCard;
