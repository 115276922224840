import { useEffect, useReducer } from 'react';

import {
  mutateLogout,
  useIsSignedIn,
} from '@/api/authentication';
import Loader from '@/components/Loader';
import { toastErrRequest } from '@/components/Toast';
import {
  AuthContext,
  AuthContextInterface,
} from './AuthContext';
import {
  authReducer,
  initialState,
  types,
} from './AuthReducer';

type AuthProviderProps = {
  children: React.ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [state, dispatch] = useReducer(
    authReducer,
    initialState,
  );
  const isSignIn = useIsSignedIn();
  const logout = mutateLogout();

  useEffect(() => {
    if (isSignIn.data) {
      dispatch({
        type: types.login,
        payload: { user: isSignIn.data },
      });
    }
  }, [isSignIn.data]);

  useEffect(() => {
    if (isSignIn.isError) {
      dispatch({ type: types.logout });
    }
  }, [isSignIn.isError]);

  const handleLogin = (login: Api.User) => {
    dispatch({ type: types.login, payload: login });
  };

  const handleLogout = () => {
    logout
      .mutateAsync({})
      .then(() => {
        dispatch({ type: types.logout });
      })
      .catch((error: unknown) => {
        toastErrRequest(error);
      });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: AuthContextInterface = {
    ...state,
    handleLogin,
    handleLogout,
  };

  if (isSignIn.isLoading) {
    return <Loader isFullHeight />;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
