import { Breadcrumb } from 'flowbite-react';
import { useSearchParams } from 'react-router-dom';

import { HiHome } from 'react-icons/hi';

import { useUsers } from '@/api/users';
import Pagination from '@/components/Pagination';
import QueryResult from '@/components/QueryResult';
import UsersTable from '@/features/users/UsersTable';
import NavbarSidebar from '@/layouts/NavbarSidebar';

function UsersPage() {
  const [searchParams] = useSearchParams();

  const key = (
    ['createdAt', 'total'].includes(
      searchParams.get('key') ?? '',
    )
      ? searchParams.get('key')
      : 'createdAt'
  ) as OrderQuery['key'];

  const direction = (
    ['asc', 'desc'].includes(
      searchParams.get('direction') ?? '',
    )
      ? searchParams.get('direction')
      : 'desc'
  ) as OrderQuery['direction'];

  const page = Number.isNaN(
    Number(searchParams.get('page')),
  )
    ? 1
    : Number(searchParams.get('page') ?? 1);

  const limit = Number.isNaN(
    Number(searchParams.get('limit') ?? 50),
  )
    ? 10
    : Number(searchParams.get('limit') ?? 50);

  const users = useUsers({
    key,
    direction,
    page,
    limit,
  });

  return (
    <NavbarSidebar>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4   sm:flex">
        <div className="w-full">
          <div className="flex justify-between items-center gap-40">
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="">Inicio</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Pedidos</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="block items-center sm:flex">
            <div className="flex w-full items-center sm:justify-end" />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <QueryResult
          query={users}
          isFullScreenLoader={false}>
          <UsersTable users={users.data?.results ?? []} />
          {users.data && (
            <Pagination
              pagination={users.data}
              query={{
                key,
                direction,
                page,
                limit,
              }}
            />
          )}
        </QueryResult>
      </div>
    </NavbarSidebar>
  );
}

export default UsersPage;
