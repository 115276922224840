import cx from 'classnames';
import {
  Sidebar as SidebarFlowbite,
  TextInput,
} from 'flowbite-react';
import { useEffect, useState } from 'react';
import { HiSearch } from 'react-icons/hi';

import {
  MdGroup,
  MdIcecream,
  MdLocationOn,
  MdOutlineGroup,
  MdOutlineIcecream,
  MdOutlineLocationOn,
  MdOutlinePieChart,
  MdOutlineReceiptLong,
  MdOutlineStore,
  MdPieChart,
  MdReceiptLong,
  MdStore,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSidebarContext } from '../context/SidebarContext';
import isSmallScreen from '../helpers/is-small-screen';

function Sidebar() {
  const {
    isOpenOnSmallScreens: isSidebarOpenOnSmallScreens,
  } = useSidebarContext();

  const [currentPage, setCurrentPage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const newPage = window.location.pathname;

    setCurrentPage(newPage);
  }, [setCurrentPage]);

  return (
    <div
      className={cx('lg:!block', {
        hidden: !isSidebarOpenOnSmallScreens,
      })}>
      <SidebarFlowbite
        aria-label="Sidebar with multi-level dropdown example"
        collapsed={
          isSidebarOpenOnSmallScreens && !isSmallScreen()
        }>
        <div className="flex h-full flex-col justify-between py-2">
          <div>
            <form className="pb-3 md:hidden">
              <TextInput
                icon={HiSearch}
                type="search"
                placeholder="Search"
                required
                size={32}
              />
            </form>
            <SidebarFlowbite.Items>
              <SidebarFlowbite.ItemGroup>
                <SidebarFlowbite.Item
                  onClick={() => {
                    navigate('/');
                  }}
                  icon={
                    currentPage === '/'
                      ? MdPieChart
                      : MdOutlinePieChart
                  }
                  className={cx(
                    'cursor-pointer',
                    currentPage === '/'
                      ? 'bg-gray-100 '
                      : '',
                  )}>
                  Dashboard
                </SidebarFlowbite.Item>
                <SidebarFlowbite.Item
                  onClick={() => {
                    navigate('/resume');
                  }}
                  icon={
                    currentPage === '/resume'
                      ? MdStore
                      : MdOutlineStore
                  }
                  className={cx(
                    'cursor-pointer',
                    currentPage === '/resume'
                      ? 'bg-gray-100 '
                      : '',
                  )}>
                  Resumen del día
                </SidebarFlowbite.Item>
                <SidebarFlowbite.Item
                  onClick={() => {
                    navigate('/stores');
                  }}
                  icon={
                    currentPage === '/stores'
                      ? MdLocationOn
                      : MdOutlineLocationOn
                  }
                  className={cx(
                    'cursor-pointer',
                    currentPage === '/stores'
                      ? 'bg-gray-100 '
                      : '',
                  )}>
                  Tiendas
                </SidebarFlowbite.Item>
                <SidebarFlowbite.Item
                  onClick={() => {
                    navigate('/products');
                  }}
                  icon={
                    currentPage === '/products'
                      ? MdIcecream
                      : MdOutlineIcecream
                  }
                  className={cx(
                    'cursor-pointer',
                    currentPage === '/products'
                      ? 'bg-gray-100 '
                      : '',
                  )}>
                  Productos
                </SidebarFlowbite.Item>
                <SidebarFlowbite.Item
                  onClick={() => {
                    navigate('/orders');
                  }}
                  icon={
                    currentPage === '/orders'
                      ? MdReceiptLong
                      : MdOutlineReceiptLong
                  }
                  className={cx(
                    'cursor-pointer',
                    currentPage === '/orders'
                      ? 'bg-gray-100 '
                      : '',
                  )}>
                  Pedidos
                </SidebarFlowbite.Item>
                <SidebarFlowbite.Item
                  onClick={() => {
                    navigate('/users');
                  }}
                  icon={
                    currentPage === '/users'
                      ? MdGroup
                      : MdOutlineGroup
                  }
                  className={cx(
                    'cursor-pointer',
                    currentPage === '/users'
                      ? 'bg-gray-100 '
                      : '',
                  )}>
                  Usuarios
                </SidebarFlowbite.Item>
              </SidebarFlowbite.ItemGroup>
            </SidebarFlowbite.Items>
          </div>
        </div>
      </SidebarFlowbite>
    </div>
  );
}

export default Sidebar;
