import { Badge, Label } from 'flowbite-react';
import { useState } from 'react';

import { HiSave } from 'react-icons/hi';

import { updateInventoryItem } from '@/api/inventory';
import { toastMsg } from '@/components/Toast';
import useStoreCtx from '@/hooks/useStoreCtx';

type InventoryItemProps = {
  item: Api.Inventory;
};

function InventoryItem({ item }: InventoryItemProps) {
  const { storeId } = useStoreCtx();
  const [quantity, setQuantity] = useState(
    item.inventoryInStock,
  );

  const mutateUpdate = updateInventoryItem();

  const handleUpdate = async () => {
    await mutateUpdate
      .mutateAsync({
        storeId,
        itemId: item.id,
        quantity,
      })
      .then(() => {
        toastMsg({ title: 'Inventario actualizado' });
      });
  };

  return (
    <li className="p-2 flex gap-2 items-center border border-gray-50">
      <img
        src={item.imageUrl}
        alt={item.name}
        className="w-12"
      />
      <div className="flex-grow">
        <Label>{item.name}</Label>
      </div>
      <div>
        <Badge color="primary" className="ml-auto">
          {item.inventoryCost}
        </Badge>
      </div>
      <div className="flex items-center justify-end">
        <input
          type="number"
          className="w-28 text-sm rounded-l-md border border-green-300 h-10 border-r-0"
          name="quantity"
          id="quantity"
          value={quantity}
          onChange={(e) => {
            setQuantity(Number(e.target.value));
          }}
        />
        <button
          type="button"
          className="text-xs p-1 bg-green-300 rounded-r-md h-10 text-white"
          onClick={handleUpdate}>
          <HiSave className="text-xl" />
        </button>
      </div>
    </li>
  );
}

export default InventoryItem;
