import { useQuery } from 'react-query';
import { axios } from './axios';

import makeMutation from '@/hooks/makeMutation';

const getProducts = (): Promise<
  Api.Response<Api.Product[]>
> => axios.get('/admin/products');
const getProduct = (id: number): Promise<Api.Product> =>
  axios.get(`/admin/products/${id}`);
const postProduct = (product: Omit<Api.Product, 'id'>) =>
  axios.post('/admin/products', product);
const putProduct = (product: Partial<Api.Product>) =>
  axios.put(`/admin/products/${product.id}`, product);
const deleteProduct = (id: number) =>
  axios.delete(`/admin/products/${id}`);

const useProducts = () => useQuery('products', getProducts);
const useProduct = (id: number) =>
  useQuery(['products', id], () => getProduct(id));
const mutateProduct = makeMutation(
  ['/product'],
  postProduct,
  ['products'],
);
const mutateProductUpdate = makeMutation(
  ['/product'],
  putProduct,
  ['products'],
);

const mutateProductDelete = makeMutation(
  ['/product'],
  deleteProduct,
  ['products'],
);

export {
  mutateProduct,
  mutateProductDelete,
  mutateProductUpdate,
  useProduct,
  useProducts,
};
