import makeMutation from '@/hooks/makeMutation';
import { axios } from './axios';

export type IngredientLimit = {
  id: number;
  maxOptions: number;
  sizeId: number;
  ingredientId: number;
};

const PATH = '/admin/productSize';

const postProductIngredientLimit = (
  ingredientLimit: Omit<IngredientLimit, 'id'>,
) =>
  axios.post(
    `${PATH}/${ingredientLimit.sizeId}/options`,
    ingredientLimit,
  );
const putProductIngredientLimit = (
  ingredientLimit: Partial<IngredientLimit>,
) =>
  axios.put(
    `${PATH}/${ingredientLimit.sizeId}/options/${ingredientLimit.id}`,
    ingredientLimit,
  );

const mutateProductIngredientLimit = makeMutation(
  ['/ingredientLimits'],
  postProductIngredientLimit,
  ['productIngredients', 'ingredientLimitsAvailable'],
);
const mutateProductIngredientLimitUpdate = makeMutation(
  ['/ingredientLimits'],
  putProductIngredientLimit,
  ['productIngredients', 'ingredientLimitsAvailable'],
);

export {
  mutateProductIngredientLimit,
  mutateProductIngredientLimitUpdate,
};
