import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

import { makeOrderCompleted } from '@/api/orders';
import Spinner from '@/components/Spinner';

function OnProcessingActions({
  order,
}: {
  order: Api.Order;
}) {
  const completeOrder = makeOrderCompleted();

  return (
    <div className="flex justify-end gap-4">
      {completeOrder.isLoading && <Spinner />}
      {!completeOrder.isLoading && (
        <button
          type="button"
          onClick={() => {
            completeOrder.mutate(order.id);
          }}
          className="flex gap-1 items-center border-2 border-primary p-1 rounded-md text-white bg-main transition-colors hover:text-primary hover:bg-white">
          <IoMdCheckmarkCircleOutline /> Marcar entregado
        </button>
      )}
    </div>
  );
}

export default OnProcessingActions;
