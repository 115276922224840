import { Navbar } from 'flowbite-react';
import { HiMenuAlt1, HiSearch, HiX } from 'react-icons/hi';
import { useSidebarContext } from '../context/SidebarContext';
import isSmallScreen from '../helpers/is-small-screen';
import UserDropdown from './UserDropdown';

function ExampleNavbar() {
  const {
    isOpenOnSmallScreens,
    isPageWithSidebar,
    setOpenOnSmallScreens,
  } = useSidebarContext();

  return (
    <Navbar fluid className="custom">
      <div className="w-full p-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {isPageWithSidebar && (
              <button
                type="button"
                onClick={() => {
                  setOpenOnSmallScreens(
                    !isOpenOnSmallScreens,
                  );
                }}
                className="mr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900    lg:inline">
                <span className="sr-only">
                  Toggle sidebar
                </span>
                {isOpenOnSmallScreens && isSmallScreen() ? (
                  <HiX className="h-6 w-6" />
                ) : (
                  <HiMenuAlt1 className="h-6 w-6" />
                )}
              </button>
            )}
            <Navbar.Brand href="/">
              <img
                alt=""
                src="/logo.png"
                className="mr-3 h-6 sm:h-8"
              />
            </Navbar.Brand>
          </div>
          <div className="flex items-center lg:gap-3">
            <div className="flex items-center">
              <button
                type="button"
                onClick={() => {
                  setOpenOnSmallScreens(
                    !isOpenOnSmallScreens,
                  );
                }}
                className="cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100      lg:hidden">
                <span className="sr-only">Search</span>
                <HiSearch className="h-6 w-6" />
              </button>
            </div>
            <div className="hidden lg:block">
              <UserDropdown />
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
}

export default ExampleNavbar;
