import cx from 'classnames';

import { formatOrderOutput } from '@/helpers/formatOrderOutput';
import formatMoney from '@/helpers/money';

type OrderDetailsPrintProps = {
  order: Api.Order;
  isPrinting: boolean;
};

function OrderDetailsPrint({
  order,
  isPrinting,
}: OrderDetailsPrintProps) {
  return (
    <div
      className={cx('hidden', {
        'print:block': isPrinting,
      })}>
      <h4 className="text-black text-base font-serif">{`Pedido #${order.id}`}</h4>
      <table className="w-full border-dashed border-b border-black">
        <thead>
          <tr className="border-dashed border-b border-black uppercase text-sm font-bold">
            <th className="text-left p-2 w-1/4">
              Producto
            </th>
            <th className="text-left p-2 w-1/4">
              Cantidad
            </th>
            <th className="text-left p-2 w-2/4">
              Descripción
            </th>
          </tr>
        </thead>
        <tbody>
          {order.orderItems.map((orderItem, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr
              key={`${order.id}_${orderItem.id}_${i}`}
              className="border-dashed border-b border-black">
              <td className="p-2">
                <label className="w-full block text-center mt-1">
                  {orderItem.product.name}
                  {orderItem.productSize &&
                    ` ${orderItem.productSize.name}`}
                  {orderItem.product.basePrice &&
                    ` - ${formatMoney(
                      orderItem.product.basePrice,
                    )}`}
                  {!orderItem.product.basePrice &&
                    orderItem.productSize.basePrice &&
                    ` - ${formatMoney(
                      orderItem.productSize.basePrice,
                    )}`}
                </label>
              </td>
              <td>{`x ${orderItem.quantity}`}</td>
              <td className="py-2">
                {formatOrderOutput(
                  orderItem.productSteps,
                ).map((step: Record<string, string>) => (
                  <div
                    key={`${order.id}${orderItem.id}${JSON.stringify(step)}`}>
                    <strong>{step.step}</strong>
                    <br />
                    {step.rawMaterial}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrderDetailsPrint;
