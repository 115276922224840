import { Button } from 'flowbite-react';

import { MdOutlineCancel } from 'react-icons/md';
import { TbCurrencyEuro } from 'react-icons/tb';

import {
  makeOrderCancel,
  makeOrderSuccess,
} from '@/api/orders';
import Spinner from '@/components/Spinner';

type OnHoldActionsProps = {
  order: Api.Order;
  onSuccess: (orderId: number) => void;
};

function OnHoldActions({
  order,
  onSuccess,
}: OnHoldActionsProps) {
  const processOrder = makeOrderSuccess();
  const cancelOrder = makeOrderCancel();

  const handleProcessOrder = async () => {
    onSuccess(order.id);
    await processOrder.mutateAsync(order.id);
    window.print();
  };

  return (
    <div className="flex justify-end gap-4">
      {(processOrder.isLoading ||
        cancelOrder.isLoading) && <Spinner />}
      {!processOrder.isLoading &&
        !cancelOrder.isLoading && (
          <>
            <Button
              color="main"
              onClick={handleProcessOrder}
              className="w-full lg:w-auto">
              <TbCurrencyEuro className="ml-1 text-xl" />
              Marcar Pagado
            </Button>
            <Button
              color="main"
              onClick={() => {
                cancelOrder.mutate(order.id);
              }}
              className="w-full lg:w-auto">
              <MdOutlineCancel className="ml-1 text-xl" />
              Cancelar Pedido
            </Button>
          </>
        )}
    </div>
  );
}

export default OnHoldActions;
