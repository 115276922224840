import { Modal } from 'flowbite-react';
import { useState } from 'react';

import { HiPencilAlt } from 'react-icons/hi';

import {
  IngredientLimit,
  mutateProductIngredientLimit,
  mutateProductIngredientLimitUpdate,
} from '@/api/ingredientLimit';
import {
  toastErrRequest,
  toastMsg,
} from '@/components/Toast';
import ProductIngredientLimitForm from '../ProductIngredientLimitForm';

type ProductIngredientLimitModalUpdateProps = {
  productSizeMaxOptionsId: number | undefined;
  productSize: Api.ProductSize & {
    maxOptions: Api.ProductIngredientMaxOption | undefined;
  };
  productIngredient: Api.ProductIngredient;
};

function ProductIngredientLimitModalUpdate({
  productSizeMaxOptionsId,
  productSize,
  productIngredient,
}: ProductIngredientLimitModalUpdateProps) {
  const [isOpen, setOpen] = useState(false);
  const mutate = mutateProductIngredientLimit();
  const mutateUpdate = mutateProductIngredientLimitUpdate();

  const handleCreateLimit = (
    values: Pick<
      Api.ProductIngredientMaxOption,
      'maxOptions'
    >,
  ) => {
    const ingredientLimit: Omit<IngredientLimit, 'id'> = {
      maxOptions: values.maxOptions,
      sizeId: productSize.id,
      ingredientId: productIngredient.id,
    };
    mutate
      .mutateAsync(ingredientLimit)
      .then(() => {
        setOpen(false);
        toastMsg({ title: 'Limite actualizado' });
      })
      .catch((error: unknown) => {
        toastErrRequest(error);
      });
  };

  const handleUpdateLimit = (
    values: Pick<
      Api.ProductIngredientMaxOption,
      'maxOptions'
    >,
  ) => {
    if (!productSizeMaxOptionsId) return;

    const ingredientLimit: IngredientLimit = {
      id: productSizeMaxOptionsId,
      maxOptions: values.maxOptions,
      sizeId: productSize.id,
      ingredientId: productIngredient.id,
    };
    mutateUpdate
      .mutateAsync(ingredientLimit)
      .then(() => {
        setOpen(false);
        toastMsg({ title: 'Limite actualizado' });
      })
      .catch((error: unknown) => {
        toastErrRequest(error);
      });
  };

  const handleProductIngredientLimitUpdate = (
    values: Pick<
      Api.ProductIngredientMaxOption,
      'maxOptions'
    >,
  ) => {
    if (productSizeMaxOptionsId) {
      handleUpdateLimit(values);
    } else {
      handleCreateLimit(values);
    }
  };

  return (
    <>
      <button
        type="button"
        className="flex items-center justify-center text-xs text-blue-700 mt-2 hover:text-blue-500"
        onClick={() => {
          setOpen(!isOpen);
        }}>
        <HiPencilAlt className="mr-1 text-sm" />
        Editar
      </button>
      <Modal
        onClose={() => {
          setOpen(false);
        }}
        show={isOpen}
        size="sm">
        <Modal.Header className="border-b border-gray-200 !p-6 ">
          <strong>Editar porciones</strong>
        </Modal.Header>
        <Modal.Body>
          <h2 className="text-center">
            {productIngredient.name}
          </h2>
          <ProductIngredientLimitForm
            callback={handleProductIngredientLimitUpdate}
            initialState={{
              maxOptions:
                productSize.maxOptions?.maxOptions ?? 0,
              productSize,
            }}
            isLoading={
              mutate.isLoading || mutateUpdate.isLoading
            }
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductIngredientLimitModalUpdate;
